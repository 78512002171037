import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import { ResponseClient } from 'src/shared/types/swagger'

export const asyncClient = createAsyncThunk<ResponseClient, void, { rejectValue: string }>(
	'clients/agreement',
	async (_, { rejectWithValue }) => {
		try {
			const response = await $api.get(
				`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/`
			)
			return response.data
		} catch (error: any) {
			console.info('Client Slice', error)
			return rejectWithValue(error.message || 'Failed to fetch client')
		}
	}
)

interface IInitialState {
	loading: boolean
	error: string | null | unknown
	clientData: null | ResponseClient
}

const initialState: IInitialState = {
	clientData: null,
	loading: false,
	error: null
}

export const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		clientLogout: state => {
			state.clientData = null
		}
	},

	extraReducers: builder => {
		builder
			.addCase(asyncClient.pending, state => {
				state.loading = true
				state.error = null
			})
			.addCase(asyncClient.fulfilled, (state, action: PayloadAction<ResponseClient>) => {
				state.loading = false
				state.clientData = action.payload
			})
			.addCase(asyncClient.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
			})
	}
})

export const { clientLogout } = clientSlice.actions
export default clientSlice.reducer
