import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useForm } from 'react-hook-form'
import Panel from 'src/components/common/panel/Panel'
import AnnounceUi from 'src/components/ui/announce/Announce'
import Button from 'src/components/ui/button/Button'
import CopyButton from 'src/components/ui/copyButton/CopyButton'
import Input from 'src/components/ui/input/Input'
import Loader from 'src/components/ui/loader/Loader'
import TooltipUi from 'src/components/ui/tooltip/TooltipUi'
import { PAYMENT } from 'src/routes/data'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import useModal from 'src/shared/hooks/useModal'
import { AutopayConfirm, ResponseAutopayConfirm } from 'src/shared/types/swagger'

import './AutoPaymentNew.scss'

const AutoPaymentNew: FC = () => {
	const {
		register,
		watch,
		formState: { errors },
		setValue,
		getValues,
		handleSubmit
	} = useForm({
		mode: 'onChange',
		defaultValues: { auto_payment_amount: 10, email: '', auto_payment_day: 1 }
	})

	const [data, setData] = useState<AutopayConfirm | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [rangeValue, setRangeValue] = useState('1')
	const [writeDay, setWriteDay] = useState('')
	const { open } = useModal()

	//Склонение даты списания
	useEffect(() => {
		setWriteDay(`Каждое ${rangeValue} число месяца`)
	}, [rangeValue])
	//Склонение даты списания

	//Первичный запрос
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseAutopayConfirm> = await $api.get(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/autopay/`
				)
				const { data } = response.data

				if (data) {
					setData(data)
					setValue('email', data.email)
				}
			} catch (error: any) {
				console.error('Failed to fetch autoPaymen edit:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичный запрос

	//Прелоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}
	//Прелоадер

	const onSubmit = async () => {
		setIsLoading(true)

		const body = {
			amount: getValues('auto_payment_amount'),
			auto_payment_day: getValues('auto_payment_day'),
			auto_payment_amount: getValues('auto_payment_amount'),
			email: data?.email,
			auto_payment: 1
		}
		;(async () => {
			try {
				const response = await $api.post(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/payments/create-autopay/`,
					body
				)
				const { data } = response.data
				if (data.payment_url) {
					open({
						text: data.redirect_text,
						modalType: 'success',
						href: data.payment_url
					})
				}
			} catch (error: any) {
				console.error(error)
				open({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		})()
	}

	return (
		data && (
			<div className="payment-page payment-page--page react-autopayment-new">
				<Panel text={'Оплата'} linkText={'Оплата'} href={PAYMENT + '/autopay'} />
				<section className="section section--first-panel" data-real-tabs="">
					<div className="container">
						{data.announces && (
							<div className="react-autopayment-new__announce">
								{data.announces.map(item => (
									<AnnounceUi key={item.announce_id} text={item.message} />
								))}
							</div>
						)}

						<form className="payment-page__row" onSubmit={handleSubmit(onSubmit)}>
							<div className="payment-page__col autopay">
								<div className="section__container payment-page__column">
									<div className="payment-page__wrapper">
										<div className="payment-page__column-top">
											<h2 className="section__title">{data.title}</h2>
											<div className="autopay__copy">
												<div className="payment-page__label">
													Номер договора
												</div>
												<div className="payment-page__refill-copy">
													<CopyButton
														text={data.agreement}
														size={true}
														variant={2}
													/>
												</div>
											</div>
											<div className="autopay__bot">
												<div className="autopay__title">
													Выбор даты и суммы списания
												</div>
												<div className="autopay__grid">
													{/* //Дата пополнения */}

													<div className="autopay__item">
														<div className="react-autopay__item">
															<p>День</p>
															{data.tooltip_date && (
																<TooltipUi
																	text={data.tooltip_date}
																/>
															)}
														</div>
														<Input
															register={register}
															filled={!!watch('auto_payment_day')}
															name="auto_payment_day"
															error={errors.auto_payment_day}
															validation={{
																required: 'Заполните поле',
																min: {
																	value: 1,
																	message: 'Некорректная дата'
																},
																max: {
																	value: 31,
																	message: 'Некорректная дата'
																}
															}}
															type="number"
														/>
													</div>
													{/* //Дата пополнения */}
													{/* //Дата пополнения */}

													<div className="autopay__item">
														<div className="react-autopay__item">
															<p>Сумма пополнения</p>

															{data.tooltip_date && (
																<TooltipUi
																	text={data.tooltip_amount}
																/>
															)}
														</div>
														<Input
															register={register}
															filled={!!watch('auto_payment_amount')}
															name="auto_payment_amount"
															error={errors.auto_payment_amount}
															options={{
																required: 'Заполните поле',
																min: {
																	value: 10,
																	message:
																		'Минимальная сумма 10 рублей'
																},
																max: {
																	value: 100000,
																	message:
																		'Максимальная сумма 30 000 рублей'
																}
															}}
															type="number"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="payment-page__col">
								<div className="section__container payment-page__column">
									<div className="payment-page__pay">
										<div className="payment-page__pay-row">
											<div className="payment-page__pay-heading">
												Сумма платежа:
											</div>
											<div className="payment-page__pay-price">
												{watch('auto_payment_amount')} ₽
											</div>
										</div>

										<div className="payment-page__pay-offset">
											<div className="payment-page__pay-row">
												<div className="payment-page__pay-heading">
													День платежа:
												</div>
												<div className="payment-page__pay-price">
													{watch('auto_payment_day')}
												</div>
											</div>
										</div>
										<div className="payment-page__pay-offset">
											<div className="payment-page__pay-heading">
												Email адрес
											</div>
										</div>
										<div className="payment-page__pay-offset">
											<div className="autopay__item">
												<div className="react-autopay__item">
													<p>Способ доставки чека</p>

													{data.tooltip_check_delivery && (
														<TooltipUi
															text={data.tooltip_check_delivery}
														/>
													)}
												</div>

												<Input
													register={register}
													filled={!!watch('email')}
													name="email"
													error={errors.email}
													validation={{
														required: 'Заполните поле',
														pattern: {
															value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
															message: 'Неверный формат'
														}
													}}
												/>
											</div>
										</div>
										<div className="payment-page__pay-offset">
											<Button
												text={'Подключить автоплатеж'}
												type="submit"
												href={data.redirect_url}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</section>
			</div>
		)
	)
}

export default AutoPaymentNew
