import { FC } from 'react'

import SidebarRight from 'src/components/common/sidebarRight/SidebarRight'
import { useAppSelector } from 'src/shared/hooks/redusHooks'

//TODO: сделать обратный вызов

interface IProps {
	state: boolean
	setState: (prev: any) => void
}
const PhoneSidebar: FC<IProps> = ({ setState, state }) => {
	const {
		contacts: { data }
	} = useAppSelector(state => state.app)

	return (
		data && (
			<SidebarRight state={state} setState={setState}>
				<div
					className="phone-modal side-modal"
					data-fancy-modal="phone"
					style={{ display: 'inline-block' }}
				>
					<div className="side-modal__top">
						<div
							className="side-modal__icon"
							data-fancybox-close=""
							onClick={() => {
								setState((prev: boolean) => !prev)
							}}
						>
							<svg
								className="side-modal__svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19 10.9998H9L12.29 7.70981C12.3837 7.61685 12.4581 7.50625 12.5089 7.38439C12.5597 7.26253 12.5858 7.13183 12.5858 6.99981C12.5858 6.8678 12.5597 6.7371 12.5089 6.61524C12.4581 6.49338 12.3837 6.38278 12.29 6.28982C12.1026 6.10356 11.8492 5.99902 11.585 5.99902C11.3208 5.99902 11.0674 6.10356 10.88 6.28982L6.59 10.5898C6.21441 10.9632 6.00223 11.4702 6 11.9998C6.00487 12.5259 6.21684 13.0289 6.59 13.3998L10.88 17.6998C10.9732 17.7924 11.0838 17.8657 11.2054 17.9155C11.3269 17.9654 11.4571 17.9908 11.5885 17.9904C11.7199 17.9899 11.8499 17.9636 11.9712 17.9128C12.0924 17.8621 12.2024 17.788 12.295 17.6948C12.3876 17.6016 12.4609 17.491 12.5107 17.3694C12.5606 17.2479 12.586 17.1177 12.5856 16.9863C12.5851 16.8549 12.5588 16.7249 12.508 16.6036C12.4573 16.4824 12.3832 16.3724 12.29 16.2798L9 12.9998H19C19.2652 12.9998 19.5196 12.8945 19.7071 12.7069C19.8946 12.5194 20 12.265 20 11.9998C20 11.7346 19.8946 11.4802 19.7071 11.2927C19.5196 11.1052 19.2652 10.9998 19 10.9998Z"
									fill="#0076D8"
								/>
							</svg>
						</div>
						<div className="side-modal__text">Телефоны служб</div>
					</div>
					<div className="phone-modal__container">
						<div className="phone-modal__item">
							<div className="phone-modal__text">{data.contact_center.title}</div>
							<a
								className="phone-modal__title"
								href={`tel:${data.contact_center.phone}`}
							>
								{data.contact_center.phone}
							</a>
							<div className="phone-modal__caption">
								{data.contact_center.working_hours}
							</div>
						</div>
						<div className="phone-modal__item">
							<div className="phone-modal__text">{data.technical_support.title}</div>
							<a
								className="phone-modal__title"
								href={`tel:${data.technical_support.phone}`}
							>
								{data.technical_support.phone}
							</a>
							<div className="phone-modal__caption">
								{data.technical_support.working_hours}
							</div>
						</div>
						{/* //TODO: раскомментить когда будет обратный вызов */}
						{/* <div className="phone-modal__item">
              <div className="phone-modal__btn">
                <Button
                  text={'Обратный звонок'}
                  theme={'outline'}
                  // onClick={() => handleBackCallSidebar((prev) => !prev)}
                />
              </div>
            </div> */}
					</div>
					<button
						type="button"
						data-fancybox-close=""
						className="fancybox-button fancybox-close-small"
						title="Close"
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
						</svg>
					</button>
				</div>
			</SidebarRight>
		)
	)
}

export default PhoneSidebar
