import { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Loader from 'src/components/ui/loader/Loader'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import { Billing, ResponseBilling } from 'src/shared/types/swagger'

import Panel from '../panel/Panel'

import PaymentAutoPayment from './AutoPayment/PaymentAutoPayment'
import PaymentCredit from './Credit/Credit'
import PaymentReciepts from './PaymentReciepts/PaymentReciepts'
import PaymentReplenishment from './replenishment/PaymentReplenishment'

const Payment = () => {
	const [data, setData] = useState<Billing | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [tabIndex, setTabIndex] = useState(2)

	//Получение целевого таба при переходе из биллинга на главной стр
	const { tab } = useParams()
	useEffect(() => {
		if (!tab) return
		if (tab === 'balance') {
			setTabIndex(0)
		} else if (tab === 'autopay') {
			setTabIndex(1)
		} else if (tab === 'credit') {
			setTabIndex(2)
		} else if (tab === 'receipt') {
			setTabIndex(3)
		}
	}, [])
	//Получение целевого таба при переходе из биллинга на главной стр

	//Первичный запрос
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseBilling> = await $api.get(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/billing/`
				)
				const { data } = response.data

				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch client:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичный запрос

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		<>
			{data && (
				<div className="payment-page payment-page--page z-index-auto">
					<Panel text={'Оплата'} />
					<section className="section section--first-panel">
						<div className="container">
							<Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
								<TabList className="tabs tabs--wide">
									<div className="tabs__list">
										<Tab selectedClassName="active" className="tabs__item">
											Пополнение баланса
										</Tab>
										<Tab selectedClassName="active" className="tabs__item">
											Автоплатеж
										</Tab>
										<Tab selectedClassName="active" className="tabs__item">
											Условный платеж
										</Tab>
										<Tab selectedClassName="active" className="tabs__item">
											Квитанция на оплату
										</Tab>
									</div>
								</TabList>

								<div className="payment-page__inner">
									<TabPanel className="payment-page__block active">
										<PaymentReplenishment data={data} />
									</TabPanel>

									<TabPanel>
										<PaymentAutoPayment
											data={data.autopay_info}
											agreement={data.agreement}
										/>
									</TabPanel>

									<TabPanel>
										<PaymentCredit
											data={data.credit_info}
											isLoading={isLoading}
											setIsLoading={setIsLoading}
										/>
									</TabPanel>
									<TabPanel>
										<PaymentReciepts agreement={data.agreement} />
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</section>
				</div>
			)}
		</>
	)
}

export default Payment
