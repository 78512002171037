import React, { FC } from 'react'

import { Schedule } from 'src/shared/types/swagger'

import './ServiceSchedule.scss'

interface IProps {
	data: Schedule
}

const ServiceSchedule: FC<IProps> = ({ data }) => {
	return (
		<div className="connected-page__wrapper">
			<div className="connected-page__accordion-heading">{data.title}</div>
			<div className="connected-page__inner react-schedule__inner">
				{data.schedules?.map(item => (
					<div className="connected-page__row" key={item.id_shed}>
						<div className="connected-page__row-col">
							<div className="services-page__card-label">Тариф</div>
							<div className="connected-page__text">{item.name_task}</div>
						</div>
						<div className="connected-page__row-col">
							<div className="connected-page__row-item">
								<div className="services-page__card-label">Кем запланирован</div>
								<div className="connected-page__text">{item.created_by}</div>
							</div>
							<div className="connected-page__row-item">
								<div className="services-page__card-label">Дата старта</div>
								<div className="connected-page__text">{item.start_date}</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default ServiceSchedule
