import { AxiosResponse } from 'axios'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import { BaseResponse, RecModulesRequest } from 'src/shared/types/swagger'

export const sendVideoModulesChange = async ({
	body,
	service_cid
}: {
	body: RecModulesRequest
	service_cid: string
}) => {
	const response: AxiosResponse<BaseResponse> = await $api.post(
		`${API_DOMAIN}services/rec/${service_cid}/modules/`,
		body
	)
	if (response) {
		return response
	}
}
