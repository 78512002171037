import { Navigate, Outlet } from 'react-router-dom'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'

import { LOGIN } from './data'

const PrivateRoute = () => {
	const token = getFromLocalStorage('token')
	return token ? <Outlet /> : <Navigate to={LOGIN} />
}

export default PrivateRoute
