import React, { FC } from 'react'

import Button from 'src/components/ui/button/Button'
import useModal from 'src/shared/hooks/useModal'
import { Subscription } from 'src/shared/types/swagger'
import { keyRender } from 'src/shared/utils/keyRender'

import './ProifleSubscriptionsPrev.scss'

interface IProps {
	data: Subscription[]
	handleSubscriptionSetting: () => void
}
const ProfileSubscriptionsPrev: FC<IProps> = ({ data, handleSubscriptionSetting }) => {
	const { open } = useModal()
	return (
		<div className="profile-page__news-container r-profile-subscr">
			<div className="profile-page__text">Получать предложения, советы и новости:</div>
			<div className="profile-page__inner">
				<div className="profile-page__row">
					<div className="profile-page__col">
						<div className="profile-page__list">
							{data &&
								data.map(item => (
									<div className="profile-page__list-wrapper" key={keyRender()}>
										<div className="profile-page__list-item">
											<div className="profile-page__list-icon">
												{item.is_subscribed ? (
													<svg
														className="profile-page__list-svg"
														width={20}
														height={20}
														viewBox="0 0 20 20"
														fill="none"
													>
														<g clipPath="url(#clip0_3552_75623)">
															<circle
																cx={10}
																cy={10}
																r={10}
																fill="#56BD68"
															/>
															<path
																d="M7.77736 13.2665L4.51152 10.0006C4.35525 9.84442 4.14333 9.75666 3.92236 9.75666C3.70139 9.75666 3.48946 9.84442 3.33319 10.0006C3.17696 10.1569 3.0892 10.3688 3.0892 10.5898C3.0892 10.8108 3.17696 11.0227 3.33319 11.179L6.59902 14.4448C6.7538 14.5996 6.93756 14.7225 7.13981 14.8063C7.34206 14.8901 7.55885 14.9332 7.77777 14.9332C7.9967 14.9332 8.21348 14.8901 8.41573 14.8063C8.61799 14.7225 8.80175 14.5996 8.95652 14.4448L16.6665 6.73482C16.8227 6.57855 16.9105 6.36662 16.9105 6.14565C16.9105 5.92468 16.8227 5.71276 16.6665 5.55649C16.5102 5.40026 16.2983 5.3125 16.0774 5.3125C15.8564 5.3125 15.6445 5.40026 15.4882 5.55649L7.77736 13.2665Z"
																fill="white"
															/>
														</g>
														<defs>
															<clipPath id="clip0_3552_75623">
																<rect
																	width={20}
																	height={20}
																	fill="white"
																/>
															</clipPath>
														</defs>
													</svg>
												) : (
													<svg
														width={20}
														height={20}
														viewBox="0 0 20 20"
														fill="none"
													>
														<g clipPath="url(#clip0_3552_75623)">
															<circle
																cx={10}
																cy={10}
																r={10}
																fill="#929CA5"
															/>
															<path
																d="M13.9024 6.0976C13.8399 6.03511 13.7551 6 13.6667 6C13.5783 6 13.4936 6.03511 13.4311 6.0976L10 9.52866L6.56894 6.0976C6.50643 6.03511 6.42166 6 6.33327 6C6.24488 6 6.16011 6.03511 6.0976 6.0976C6.03511 6.16011 6 6.24488 6 6.33327C6 6.42166 6.03511 6.50643 6.0976 6.56894L9.52866 10L6.0976 13.4311C6.03511 13.4936 6 13.5783 6 13.6667C6 13.7551 6.03511 13.8399 6.0976 13.9024C6.16011 13.9649 6.24488 14 6.33327 14C6.42166 14 6.50643 13.9649 6.56894 13.9024L10 10.4713L13.4311 13.9024C13.4936 13.9649 13.5783 14 13.6667 14C13.7551 14 13.8399 13.9649 13.9024 13.9024C13.9649 13.8399 14 13.7551 14 13.6667C14 13.5783 13.9649 13.4936 13.9024 13.4311L10.4713 10L13.9024 6.56894C13.9649 6.50643 14 6.42166 14 6.33327C14 6.24488 13.9649 6.16011 13.9024 6.0976Z"
																fill="white"
																stroke="white"
																strokeWidth="0.9"
															/>
														</g>
														<defs>
															<clipPath id="clip0_3552_75623">
																<rect
																	width={20}
																	height={20}
																	fill="white"
																/>
															</clipPath>
														</defs>
													</svg>
												)}
											</div>
											<div className="profile-page__list-text">
												{item.description}
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
					<div className="profile-page__col">
						<div className="profile-page__btn alt">
							{process.env.REACT_APP_ENV !== 'production' ? (
								<Button
									text={'Настроить'}
									onClick={handleSubscriptionSetting}
									size={'small'}
									theme={'outline'}
								/>
							) : (
								<Button
									text={'Настроить'}
									onClick={() =>
										open({
											text: 'Этот раздел находится в разработке.',
											modalType: null,
											description:
												'Попробуйте позже или обратитесь в контактный центр по номеру: 8 (812) 595-81-21'
										})
									}
									size={'small'}
									theme={'outline'}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileSubscriptionsPrev
