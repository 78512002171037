import { FC } from 'react'

import { Message } from 'src/shared/types/swagger'

interface IProps {
	data: Message
}
const NotificationItem: FC<IProps> = ({ data }) => {
	return (
		data && (
			<div className="notification-modal__item">
				<div className="notification-modal__info">
					<div className="notification-modal__icon" />
					<div className="notification-modal__date">{data.created}</div>
				</div>
				<div className="notification-modal__title">{data.title}</div>
				<div className="notification-modal__text">{data.body}</div>
			</div>
		)
	)
}

export default NotificationItem
