import { FC } from 'react'

import { ServiceStatusEnum } from 'src/shared/types/swagger'

import './ServiceStatus.scss'

interface IProps {
	status: ServiceStatusEnum
}

const ServiceStatus: FC<IProps> = ({ status }) => {
	if (status === ServiceStatusEnum.Active) {
		return status && <span className="r-status">Активна</span>
	} else if (status === ServiceStatusEnum.Suspended) {
		return status && <span className="r-status r-status_suspended">Приостановлена</span>
	} else if (status === ServiceStatusEnum.Blocked) {
		return status && <span className="r-status r-status_blocked">Блокирована</span>
	} else {
		return null
	}
}

export default ServiceStatus
