import { FC } from 'react'

import Button from 'src/components/ui/button/Button'
import Loader from 'src/components/ui/loader/Loader'
import Modal from 'src/components/ui/modal/Modal'
import { closeConditionModal } from 'src/redux/slices/conditionSlice'
import { useAppDispatch, useAppSelector } from 'src/shared/hooks/redusHooks'
import { ServiceCondition } from 'src/shared/types/swagger'

import './ModalStyle.scss'

const ConditionModal: FC = () => {
	const {
		conditionData: { condition: data },
		loading,
		isOpen
	} = useAppSelector(state => state.condition)
	const dispatch = useAppDispatch()

	const handleCloseModal = () => {
		dispatch(closeConditionModal())
	}

	return (
		<Modal state={isOpen} setState={handleCloseModal} callback={handleCloseModal} zIndex={1001}>
			<Loader state={loading} isInner />;
			<div className="response-modal options-modal r-condition-modal">
				<div className="r-condition-modal__close" onClick={handleCloseModal}>
					<svg
						className="response-modal__close-svg"
						xmlns="http://www.w3.org/2000/svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
							fill="#001F38"
						/>
					</svg>
				</div>
				<div className="r-condition-modal__header"></div>
				<div className="r-condition-modal__content ">
					{data && (
						<div className="react-conditions-content">
							{data.text_type === ServiceCondition.TextTypeEnum.Plain ? (
								<p>{data.text}</p>
							) : (
								<div dangerouslySetInnerHTML={{ __html: data.text }} />
							)}
						</div>
					)}
				</div>
				<div className="r-condition-modal__footer">
					<div className="r-condition-modal__controls">
						<Button text="Ок" onClick={handleCloseModal} />
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ConditionModal
