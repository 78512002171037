import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { parse } from 'date-fns'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import CalendarRange from 'src/components/ui/calendar2/CalendarRangeUi'
import Loader from 'src/components/ui/loader/Loader'
import { COSTCONTROL } from 'src/routes/data'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import { PhoneCallOverview, ResponsePhoneCallOverview } from 'src/shared/types/swagger'
import { formatDate } from 'src/shared/utils/formatDate'

import Panel from '../panel/Panel'

//TODO: проверить если несколько телефонов нужно два телефона от пакта

const PhoneDetail: FC = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState<PhoneCallOverview>()
	const [tabIndex, setTabIndex] = useState(0)
	const [totalAmountDetail, setTotalAmountDetail] = useState(0)
	const [date, setDate] = useState<Date[]>()

	//Запрос данных
	const fetchData = async (date?: DateRange | undefined) => {
		setIsLoading(true)

		try {
			const response: AxiosResponse<ResponsePhoneCallOverview> = await $api.get(
				`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/phone_calls/${
					date
						? `?start_date=${formatDate(date[0], 'send')}&` +
							`end_date=${formatDate(date[1], 'send')}`
						: ''
				}`
			)

			const { data } = response.data
			if (data) {
				setData(data)

				if (data?.calls) {
					setTotalAmountDetail(data?.calls[tabIndex]?.total_amount)
				}

				//установка значения периода при загурзке
				setDate([
					parse(data.start_date, 'dd.MM.yyyy', new Date()),
					parse(data.end_date, 'dd.MM.yyyy', new Date())
				])
				//установка значения периода при загурзке
			}
		} catch (error: any) {
			console.error('Failed to fetch client:', error.message)
		} finally {
			setIsLoading(false)
		}
	}
	//Запрос данных

	//Первичные данные
	useEffect(() => {
		fetchData()
	}, [])
	//Первичные данные

	// установление затраченной суммы в зависимости от открытого таба
	useEffect(() => {
		if (data) {
			setTotalAmountDetail(data?.calls?.[tabIndex]?.total_amount || 0)
		}
	}, [tabIndex])
	// установление затраченной суммы в зависимости от открытого таба

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return data ? (
		<div className="costs-page">
			<Panel text="Детализация расходов" href={COSTCONTROL} linkText={'Контроль расходов'} />

			<section className="section section--first-panel">
				<div className="container">
					<div className="section__container">
						<div className="costs-page__wrapper">
							{/* //верхний блок */}
							<div className="costs-page__top">
								<h1 className="section__title">Детализация звонков</h1>
							</div>
							<div className="costs-page__inner">
								<div className="costs-page__inner-col">
									<div className="costs-page__calendar">
										<CalendarRange
											value={date}
											setValue={setDate}
											disable="after"
											//@ts-ignore
											callback={fetchData}
										/>
									</div>
									<div className="costs-page__balance">
										<div className="costs-page__balance-text">
											Текущий баланс:
										</div>
										<div className="costs-page__price">{data.balance} ₽</div>
									</div>
								</div>
								<div className="costs-page__inner-col" />
							</div>
							{/* //верхний блок */}

							<Tabs
								className="costs-page__calls"
								selectedIndex={tabIndex}
								onSelect={index => setTabIndex(index)}
							>
								<div className="costs-page__calls-row">
									<div className="costs-page__calls-column">
										<TabList className="categories">
											{data?.calls?.map((item, index) => (
												<Tab
													className={`categories__item ${
														data.calls?.length === 1
															? 'pointer-events-none '
															: ''
													}`}
													key={index}
												>
													<div className="categories__btn active">
														{item.number_phone}
													</div>
												</Tab>
											))}
										</TabList>
									</div>
									<div className="costs-page__calls-column">
										<div className="costs-page__price">
											{totalAmountDetail || 0} ₽
										</div>
									</div>
								</div>

								{data?.calls?.map((item, index) => (
									<TabPanel key={index}>
										<div className="costs-page__calls-table">
											<div className="costs-page__calls-top">
												<div className="costs-page__calls-grid">
													<div className="costs-page__calls-col">
														<div className="section__label default">
															Дата
														</div>
													</div>
													<div className="costs-page__calls-col">
														<div className="section__label default">
															Входящий номер
														</div>
													</div>
													<div className="costs-page__calls-col">
														<div className="section__label default">
															Исходящий номер
														</div>
													</div>
													<div className="costs-page__calls-col">
														<div className="section__label default">
															Код населенного пункта
														</div>
													</div>
													<div className="costs-page__calls-col">
														<div className="section__label default">
															Длительность, мин
														</div>
													</div>
													<div className="costs-page__calls-col">
														<div className="section__label default">
															Сумма
														</div>
													</div>
												</div>
											</div>
											<div className="costs-page__calls-body">
												{item?.calls?.map((call, index) => (
													<div
														className="costs-page__calls-grid"
														key={index}
													>
														<div className="costs-page__calls-col">
															<div className="section__label only-mobile">
																Дата
															</div>
															<div className="costs-page__calls-text">
																{call.date}
															</div>
														</div>
														<div className="costs-page__calls-col">
															<div className="section__label only-mobile">
																Входящий номер
															</div>
															<div className="costs-page__calls-text">
																{call.destination}
															</div>
														</div>
														<div className="costs-page__calls-col">
															<div className="section__label only-mobile">
																Исходящий номер
															</div>
															<div className="costs-page__calls-text">
																{call.source}
															</div>
														</div>
														<div className="costs-page__calls-col">
															<div className="section__label only-mobile">
																Код населенного пункта
															</div>
															<div className="costs-page__calls-text">
																{call.prefix}
															</div>
														</div>
														<div className="costs-page__calls-col">
															<div className="section__label only-mobile">
																Длительность, мин
															</div>
															<div className="costs-page__calls-text">
																{call.minutes}
															</div>
														</div>
														<div className="costs-page__calls-col">
															<div className="section__label only-mobile">
																Сумма
															</div>
															<div className="costs-page__calls-text">
																{call.cost} ₽
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
										{/* <div className="costs-page__accordions-btn alt">
                        Показать все
                      </div> */}
									</TabPanel>
								))}
							</Tabs>
						</div>
					</div>
				</div>
			</section>
		</div>
	) : null
}

export default PhoneDetail
