import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AxiosResponse } from 'axios'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import {
	AvailableAbonements,
	AvailablePlan,
	AvailablePlansForChange,
	ResponseAvailablePlansForChange
} from 'src/shared/types/swagger'

import { serviceChangeInitial } from './initial'

export const asyncServiceChange = createAsyncThunk<
	{
		data: AvailablePlansForChange
		sortedTariffs: AvailablePlan[]
		selectTariff: AvailablePlan
		selectAbonement: AvailableAbonements | null
	},
	string,
	{ rejectValue: string }
>('asyncServiceChange', async (id, { rejectWithValue }) => {
	try {
		const response: AxiosResponse<ResponseAvailablePlansForChange> = await $api.get(
			`${API_DOMAIN}services/${id}/plans/available/`
		)

		const availablePlans = response?.data?.data?.available_plans || []
		const currentPlan = response?.data?.data?.current_plan

		// Сортируем доступные тарифы
		const sortedTariffs = availablePlans.sort((a, b) => a.display_order - b.display_order)

		// Определяем выбранный тариф и абонемент
		let selectTariff: AvailablePlan | undefined
		let selectAbonement: AvailableAbonements | null = null

		if (currentPlan && !currentPlan.is_ab) {
			selectTariff = sortedTariffs.find(item => item.plan_id === currentPlan.plan_id)
		}

		// Если тариф не найден или текущий тариф абонемент, выбираем первый тариф
		if (!selectTariff) {
			selectTariff = sortedTariffs[0]
		}

		// Устанавливаем абонемент, если он есть
		if (selectTariff?.abonements?.length) {
			selectAbonement = selectTariff.abonements[0]
		}

		return { data: response.data.data, sortedTariffs, selectTariff, selectAbonement }
	} catch (error: any) {
		return rejectWithValue(error.message || 'Failed to fetch asyncServiceChange')
	}
})

const serviceChangeSlice = createSlice({
	name: 'serviceChange',
	initialState: serviceChangeInitial,
	reducers: {
		clearServiceChangeSlice: state => {
			state.data = serviceChangeInitial.data
		},
		setSelectTariff: (state, action: PayloadAction<AvailablePlan>) => {
			state.selectTariff = action.payload
		},
		setSelectAbonement: (state, action: PayloadAction<AvailableAbonements | null>) => {
			state.selectAbonement = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(asyncServiceChange.pending, state => {
				state.loading = true
			})
			.addCase(asyncServiceChange.fulfilled, (state, action) => {
				state.data = action.payload.data
				state.sortedTariffs = action.payload.sortedTariffs
				state.selectTariff = action.payload.selectTariff
				state.selectAbonement = action.payload.selectAbonement
				state.loading = false
			})
			.addCase(asyncServiceChange.rejected, state => {
				state.loading = false
			})
	}
})

export const { clearServiceChangeSlice, setSelectTariff, setSelectAbonement } =
	serviceChangeSlice.actions

export default serviceChangeSlice.reducer
