import { useCallback, useEffect, useState } from 'react'

import { parse } from 'date-fns'
import { DateRange, RangeType } from 'rsuite/esm/DateRangePicker'
import CalendarRange from 'src/components/ui/calendar2/CalendarRangeUi'
import Categories, { ICategory } from 'src/components/ui/categories/Categories'
import Loader from 'src/components/ui/loader/Loader'
import Pagination from 'src/components/ui/pagination/Pagination'
import {
	asyncNotifications,
	selectCurrentPage,
	selectFilter,
	selectItemsPerPage,
	selectMessages,
	selectPaginatedMessages,
	selectRange,
	setCurrentPage,
	setFilterCategory
} from 'src/redux/slices/notificationsSlice'
import { HOME } from 'src/routes/data'
import { useAppDispatch, useAppSelector } from 'src/shared/hooks/redusHooks'

import Panel from '../panel/Panel'

import NotificationItem from './components/item/NotificationItem'
import './style.scss'

const Notification = () => {
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const currentPage = useAppSelector(selectCurrentPage)
	const itemsPerPage = useAppSelector(selectItemsPerPage)
	const totalMessages = useAppSelector(selectMessages).length
	const range = useAppSelector(selectRange)
	const newMessagesQuantity = useAppSelector(state => state.notifications.newMessages.length)
	const filter = useAppSelector(selectFilter)
	const { notificationItems, total } = useAppSelector(selectPaginatedMessages)

	const { data } = useAppSelector(state => state.notifications)

	useEffect(() => {
		const fetchNotifications = async () => {
			setIsLoading(true)
			await dispatch(asyncNotifications())
			setIsLoading(false)
		}
		fetchNotifications()
	}, [dispatch])

	const categories: ICategory[] = [
		{ category_name: 'Все', category_code: 'all', quantity: totalMessages },
		{ category_name: 'Непрочитанные', category_code: 'new', quantity: newMessagesQuantity },
		{
			category_name: 'Прочитанные',
			category_code: 'read',
			quantity: totalMessages - newMessagesQuantity
		}
	]

	const totalPages = Math.ceil(total / itemsPerPage)

	const fetchData = async (range: RangeType<DateRange>) => {
		setIsLoading(true)
		await dispatch(asyncNotifications(range as unknown as [Date, Date]))
		setIsLoading(false)
	}

	const handlePrevious = () => {
		if (currentPage > 1) {
			dispatch(setCurrentPage(currentPage - 1))
		}
	}

	const handleNext = () => {
		if (currentPage < totalPages) {
			dispatch(setCurrentPage(currentPage + 1))
		}
	}

	const categoriesCallback = useCallback(
		(category: ICategory) => {
			dispatch(setFilterCategory(category.category_code as 'all' | 'new' | 'read'))
		},
		[dispatch]
	)

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		data && (
			<div className="notification-page r-notification-page">
				<Panel text={'Уведомления'} linkText={'Мой ПАКТ'} href={HOME} />
				<section className="section section--first-panel">
					<div className="container">
						<div className="section__container desktop">
							<div className="notification-page__wrapper">
								<h1 className="section__title">Уведомления</h1>
								<div className="notification-page__top">
									<div
										className={`notification-page__top-col ${total > 1 ? '' : 'flex gap-10 items-center'}`}
									>
										<div className="notification-page__calendar">
											<CalendarRange
												value={[
													parse(range[0], 'dd.MM.yyyy', new Date()),
													parse(range[1], 'dd.MM.yyyy', new Date())
												]}
												disable="after"
												callback={fetchData}
											/>
										</div>
									</div>
									{total && (
										<div className="notification-page__top-col">
											<Categories
												data={categories}
												callback={categoriesCallback}
												currentCategory={
													categories.find(
														item => item.category_code === filter
													)!
												}
											/>
										</div>
									)}
								</div>
								{Boolean(notificationItems.length) && (
									<div className="notification-page__list">
										{notificationItems.map((item, index) => (
											<NotificationItem data={item} key={index} />
										))}
									</div>
								)}

								{total > 1 && (
									<div className="notification-page__bot">
										<Pagination
											currentPage={currentPage}
											totalPages={totalPages}
											onPagePrev={handlePrevious}
											onPageNext={handleNext}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	)
}

export default Notification
