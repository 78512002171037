import React, { FC, useEffect, useMemo, useState } from 'react'

import SelectUi from 'src/components/ui/select/SelectUi'
import { YearlyDocuments } from 'src/entitties/documents/model/type'

import ProfileDocumentsCard from './ProfileDocumentsCard'

interface IProps {
	data: YearlyDocuments[] | undefined | null
}

const ProfileDocumentsTab: FC<IProps> = ({ data }) => {
	const [filteredData, setFilteredData] = useState<YearlyDocuments | undefined>()

	const selectOptions = useMemo(() => {
		return data?.map(item => item.select_options) || []
	}, [data])

	useEffect(() => {
		if (data && data.length > 0) {
			setFilteredData(data[0])
		}
	}, [data])

	const handleSelect = (value: string | number) => {
		const selectedData = data?.find(item => +item.year === +value)
		setFilteredData(selectedData)
	}

	return (
		<div className="profile-page__wrapper active">
			<div className="react-profile-documents__contacts-select">
				{selectOptions.length > 1 && (
					<SelectUi
						optionsValue={selectOptions}
						setValue={handleSelect}
						name="available"
					/>
				)}
			</div>
			<div className="profile-page__grid react-profile-page__box">
				{filteredData?.documents?.map(el => <ProfileDocumentsCard key={el.id} data={el} />)}
			</div>
		</div>
	)
}

export default ProfileDocumentsTab
