import React, { FC } from 'react'

import { asyncCondition } from 'src/redux/slices/conditionSlice'
import { useAppDispatch } from 'src/shared/hooks/redusHooks'
import { Condition } from 'src/shared/types/Condition'

import './ConditionUi.scss'

interface IProps {
	data: Condition | null | undefined
}

const ConditionUi: FC<IProps> = ({ data }) => {
	const dispatch = useAppDispatch()

	const handleClickLink = () => {
		dispatch(asyncCondition({ id: data?.code! }))
	}
	return (
		<>
			{data && (
				<div className="payment-page__bot-caption flex r-condition-ui">
					<div className="payment-page__pay-label" style={{ textAlign: 'start' }}>
						<p>
							{data.text}{' '}
							<span className="payment-page__link" onClick={handleClickLink}>
								{data.link}
							</span>
						</p>
					</div>
				</div>
			)}
		</>
	)
}

export default ConditionUi
