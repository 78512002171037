import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import TooltipUi from 'src/components/ui/tooltip/TooltipUi'
import { SERVICEAVAILABLENEWPLAN } from 'src/routes/data'
import { AvailablePlans, PlanAbonementInternet } from 'src/shared/types/swagger'
import { paymentDisplay } from 'src/shared/utils/paymentDisplay'

import './AvailableOptionsBlock.scss'

interface IProps {
	data: AvailablePlans
	service_type: string
}
const AvailableOptionsBlock: FC<IProps> = ({ data, service_type }) => {
	const navigate = useNavigate()

	const handleClick = (abon: PlanAbonementInternet) => {
		navigate(SERVICEAVAILABLENEWPLAN + `/${service_type}`, {
			state: {
				service_type,
				plan_id: abon.plan_id,
				plan_name: abon.plan_name,
				cost: paymentDisplay(abon.plan_fee, abon.fee_period, abon.fee_kind)
			}
		})
	}
	return (
		data && (
			<div className="services-page__item-row r-aop">
				{data?.items?.map(item => (
					<div className="services-page__item-col" key={item.plan_id}>
						<div className="services-page__card">
							<div className="services-page__card-container">
								<div className="services-page__top">
									<div className="services-page__top-col">
										<div className="services-page__card-heading tooltip flex gap-8">
											{item.plan_name}
											{item.tooltip && <TooltipUi text={item.tooltip} />}
										</div>
									</div>
									<div className="services-page__top-col">
										<div className="services-page__top-speed">{item.speed}</div>
									</div>
								</div>
								<div className="services-page__rates">
									<div className="services-page__rates-item top">
										<div className="services-page__rates-row">
											<div className="services-page__rates-col">
												<div className="services-page__rates-label">
													{item.plan_descr}
												</div>
												<div className="services-page__rates-text">
													{paymentDisplay(
														item.plan_fee,
														item.fee_period,
														item.fee_kind
													)}
												</div>
											</div>
											<div className="services-page__rates-col">
												<div
													className="services-page__card-btn"
													onClick={() =>
														handleClick({
															plan_id: item.plan_id,
															plan_name: item.plan_name,
															plan_fee: item.plan_fee,
															fee_period: item.fee_period,
															fee_kind: item.fee_kind
														} as PlanAbonementInternet)
													}
												>
													Подключить
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="services-page__rates-label services-page__rates-heading">
									Абонементы
								</div>
								<div className="services-page__rates">
									{item.abonements.map(abon => (
										<div
											className="services-page__rates-item"
											key={abon.plan_id}
										>
											<div className="services-page__rates-row start">
												<div className="services-page__rates-col">
													<div className="services-page__rates-month tooltip flex gap-8">
														{abon.plan_descr}
														{abon.tooltip && (
															<TooltipUi text={abon.tooltip} />
														)}
													</div>
													<div className="services-page__rates-text">
														{abon.plan_fee_month}
													</div>
													<div className="services-page__rates-pay">
														Разовый платеж{' '}
														<span>{abon.plan_fee} ₽</span>
													</div>
												</div>
												<div className="services-page__rates-col">
													<button
														className="services-page__card-btn"
														onClick={() => handleClick(abon)}
													>
														Подключить
													</button>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		)
	)
}

export default AvailableOptionsBlock
