import { FC, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import Button from 'src/components/ui/button/Button'
import Checkbox from 'src/components/ui/checkbox/Checkbox'
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi'
import Loader from 'src/components/ui/loader/Loader'
import ResponsePage from 'src/components/ui/responsePage/ResponsePage'
import SelectUi, { ISelectOptions } from 'src/components/ui/select/SelectUi'
import TooltipUi from 'src/components/ui/tooltip/TooltipUi'
import { selectChangeTariff } from 'src/features/service-change/model/serviceChangeSelector'
import {
	asyncServiceChange,
	setSelectAbonement,
	setSelectTariff
} from 'src/features/service-change/model/serviceChangeSlice'
import { IModalData } from 'src/redux/slices/modalSlice'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import { useAppDispatch, useAppSelector } from 'src/shared/hooks/redusHooks'
import { AvailableAbonements, AvailablePlan, UpdPlanNowRequest } from 'src/shared/types/swagger'
import { paymentDisplay } from 'src/shared/utils/paymentDisplay'

import Panel from '../../panel/Panel'

import './ServiceChangeTariff.scss'
import ChangeTariffTubs from './changeTariff/ui/tubs/ChangeTariffTubs'
import ChangeTariffModal from './components/changeTariffModal/ChangeTariffModal'

const ServiceChangeTariff: FC = () => {
	const { id } = useParams()
	const {
		data,
		sortedTariffsWithSelect,
		loading,
		currentAbonementWitchSelect,
		selectTariffWithSelect,
		abonementListWithSelect
	} = useAppSelector(selectChangeTariff)

	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [isAbonementActive, setIsAbonementActive] = useState(false)
	const [modalData, setModalData] = useState<IModalData | null>(null)

	const [isChangeModalActive, setIsChangeModalActive] = useState(false)

	useEffect(() => {
		if (!id) return
		;(async () => {
			dispatch(asyncServiceChange(id))
		})()
	}, [id])

	const handleModal = (data: IModalData) => {
		setModalData(data)
	}

	// сменить тариф сразу
	const onSubmit = async () => {
		const fetchDataChangeTariff = async () => {
			const serviceCid = data!.service_cid
			const currentPlanId = data!.current_plan.plan_id

			const body: UpdPlanNowRequest = {
				current_plan_id: currentPlanId,
				new_plan_id: selectTariffWithSelect!.value!,
				new_abon_id: isAbonementActive ? currentAbonementWitchSelect!.value : null,
				start_date: null
			}
			setIsLoading(true)

			try {
				const response = await $api.put(
					`${API_DOMAIN}services/${serviceCid}/plans/now/`,
					body
				)

				const { data } = response
				if (data) {
					handleModal({
						text: data?.data?.title || data?.client_message || 'Изменения сохранены',
						modalType: 'success',
						isReload: true,
						description: data?.data?.description || ''
					})
				}
			} catch (error: any) {
				console.error('Failed to change tariff:', error.message)
				handleModal({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}

		fetchDataChangeTariff()
	}

	// смена тарифа через планировщик
	// const onSubmitSchedule = async () => {
	// 	const fetchDataChangeTariff = async () => {
	// 		const serviceCid = data!.service_cid
	// 		const currentPlanId = data!.current_plan.plan_id

	// 		const body: UpdPlanScheduleRequest = {
	// 			start_date: formatDate(changeDate!, 'send')!,
	// 			current_plan_id: currentPlanId,
	// 			new_plan_id: isAbonementActive ? currentAbonement!.value : currentTariff!.value
	// 		}
	// 		setIsLoading(true)

	// 		try {
	// 			const response = await $api.put(
	// 				`${API_DOMAIN}services/${serviceCid}/plans/schedule/`,
	// 				body
	// 			)

	// 			const { data } = response.data
	// 			if (data) {
	// 				setSuccessData(data)
	// 				setStep(4)
	// 			}
	// 		} catch (error: any) {
	// 			console.error('Failed to change tariff:', error.message)
	// 			console.error(error)
	// 		} finally {
	// 			setIsLoading(false)
	// 		}
	// 	}

	// 	fetchDataChangeTariff()
	// }
	// смена тарифа через планировщик

	//Изменение состояние чекбокса абонементов
	const handleCheckboxAbonementActive = (value: boolean) => {
		setIsAbonementActive(value)
	}

	const tariffCallback = (option: AvailablePlan) => {
		dispatch(setSelectTariff(option))
		if (option.abonements && option.abonements.length > 0) {
			dispatch(setSelectAbonement(option.abonements[0]))
		} else {
			dispatch(setSelectAbonement(null))
		}
	}

	//Колбек при изменении абонемента
	const abonementCallback = async (option: AvailableAbonements) => {
		dispatch(setSelectAbonement(option))
	}
	//Колбек при изменении абонемента

	//Лоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}
	//Лоадер

	const handleSubmitNow = () => {
		if (isAbonementActive && data?.condition_block) {
			setIsChangeModalActive(true)
		} else {
			onSubmit()
		}
	}

	if (modalData) {
		return <ResponsePage data={modalData} />
	}

	return (
		<>
			<ChangeTariffModal
				title=""
				id={data?.condition_block?.code!}
				onSubmit={onSubmit}
				setState={setIsChangeModalActive}
				state={isChangeModalActive}
				checkboxes={data?.condition_block?.checkboxes}
			/>
			{data && selectTariffWithSelect && (
				<div className="services-page connected-page connected-page--default">
					<Panel text="Смена тарифа" linkText="Услуги" onBack={true} />
					<section className="section section--first-panel">
						<div className="container">
							<div className="section__container services-page__change">
								<div className="services-page__change-top">
									<div className="section__title">{`Смена тарифа услуги ${data.service_title}`}</div>
								</div>
								<ChangeTariffTubs step={1} />
								<form className="services-page__change-body">
									<div className="services-page__change-block active">
										<div className="connected-page__accordion-heading">
											Текущий тарифный план
										</div>
										<div className="services-page__delay-wrapper connected-page__inner">
											<div className="services-page__change-grid">
												<div className="services-page__change-wrapper">
													<div className="services-page__card-label">
														Тариф
													</div>
													<div className="connected-page__text">
														{data.current_plan.plan_name}
													</div>
												</div>
												<div className="services-page__change-wrapper">
													<div className="services-page__card-label">
														Абонентская плата
													</div>
													<div className="connected-page__text">
														{paymentDisplay(
															data.current_plan.plan_fee,
															data.current_plan.fee_period,
															data.current_plan.fee_kind
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="services-page__delay-wrapper connected-page__inner">
											<div className="services-page__change-grid">
												{/* //Доступные тарифы */}
												{sortedTariffsWithSelect && (
													<div className="services-page__change-wrapper">
														<div className="section__label default">
															Доступные тарифы
														</div>
														<div className="services-page__change-inner">
															<SelectUi
																callback={tariffCallback as any}
																optionsValue={
																	sortedTariffsWithSelect
																}
																name="available"
																defaultValue={
																	selectTariffWithSelect as ISelectOptions
																}
															/>
														</div>

														{abonementListWithSelect && (
															<div className="services-page__change-bot">
																<Checkbox
																	defaultValue={isAbonementActive}
																	callback={
																		handleCheckboxAbonementActive
																	}
																	text="Абонемент"
																/>
															</div>
														)}
													</div>
												)}
												{/* //Доступные тарифы */}

												{/* //Абонемент */}
												{abonementListWithSelect && isAbonementActive && (
													<div className="services-page__change-wrapper">
														<div className="services-page__delay-tooltip tooltip tooltip--alt react-tooltip__gap">
															<p className="section__label default">
																Подключить абонемент
															</p>

															{currentAbonementWitchSelect?.tooltip && (
																<TooltipUi
																	text={
																		currentAbonementWitchSelect.tooltip
																	}
																/>
															)}
														</div>
														<div className="services-page__change-inner">
															<SelectUi
																callback={abonementCallback as any}
																defaultValue={
																	currentAbonementWitchSelect
																}
																optionsValue={
																	abonementListWithSelect
																}
																name="abonements"
															/>
														</div>

														{/* //ссылка на условия  */}
														<ConditionUi data={data.condition_block} />
														{/* //ссылка на условия  */}
													</div>
												)}
											</div>
										</div>
										<div className="services-page__delay-bot alt">
											{data.show_change_now_btn && (
												<div className="services-page__delay-btn">
													<Button
														theme="outline"
														text="Изменить"
														size="small"
														onClick={handleSubmitNow}
													/>
												</div>
											)}
											{/* {data.show_schedule_btn && (
												<div className="services-page__delay-btn">
													<Button
														text="Запланировать"
														size="small"
														onClick={() => setStep(2)}
													/>
												</div>
											)} */}
										</div>
									</div>
								</form>
							</div>
						</div>
					</section>
				</div>
			)}
		</>
	)
}

export default ServiceChangeTariff
