import { FC } from 'react'

import { IAutopay } from 'src/shared/types/swagger'

import './PaymentAutoPayment.scss'
import AutoPaymentCard from './card/AutoPaymentCard'
import AutoPaymentEmpty from './components/AutoPaymentEmpty'

interface IProps {
	data: IAutopay
	agreement: number
}

const PaymentAutoPayment: FC<IProps> = ({ data, agreement }) => {
	return data && data.autopays ? (
		<div className="payment-page__block active r-payment-auto-payment">
			{data.autopays.map(item => (
				<AutoPaymentCard data={item} key={item.amount} agreement={agreement} />
			))}
		</div>
	) : (
		<div className="payment-page__block active">
			<AutoPaymentEmpty
				condition_block={data.condition_block}
				description={data.description}
				title={data.title}
			/>
		</div>
	)
}

export default PaymentAutoPayment
