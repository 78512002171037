import { FC, useState } from 'react'

import { saveAs } from 'file-saver'
import Button from 'src/components/ui/button/Button'
import { getDocumentById } from 'src/entitties/documents/api/getDocumentById'
import { Document } from 'src/entitties/documents/model/type'
import { convertBase64ToBlob } from 'src/shared/utils/convertBase64toBlob'

interface IProps {
	data: Document
}
const ProfileDocumentsCard: FC<IProps> = ({ data }) => {
	const [isLoading, setIsLoading] = useState(false)
	const { id, mime_type, name, size, start_date, type_doc } = data

	const handleDownload = async () => {
		setIsLoading(true)

		try {
			const res = await getDocumentById({ contract_id: data.id })

			if (res) {
				const { type, content, filename, size } = res

				if (type === 'url') {
					saveAs(content, filename)
				} else if (type === 'base64') {
					const blob = convertBase64ToBlob(content, type)
					saveAs(blob, filename)
				}
			} else {
				console.error('Error: Response is undefined or invalid')
			}
		} catch (error) {
			console.error('Error downloading the file:', error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		data && (
			<div className="profile-page__card">
				<div className="profile-page__card-container">
					<div className="profile-page__card-heading">{name}</div>
					<div className="profile-page__card-bot">
						<div className="profile-page__card-col">
							{start_date && (
								<div className="section__label default">{start_date}</div>
							)}
							<div className="section__label default">{size}</div>
						</div>

						<div className="profile-page__card-col">
							<Button
								theme="outline"
								size="small"
								text="Скачать"
								className="rounded-[100px] px-5 py-[14px] w-32"
								onClick={handleDownload}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	)
}

export default ProfileDocumentsCard
