import { FC, useState } from 'react'

import AnimateHeight from 'react-animate-height'
import { TypeSubscriptionEnum } from 'src/shared/types/swagger'

import ServiceStatus from '../../common/services/components/ui/status/ServiceStatus'
import CheckboxDefault from '../checkbox/CheckboxDefault'

import './Accordion.scss'

interface IProps {
	children: any
	title?: string
	status?: any
	price?: string | number
	checkbox?: {
		text: string
		disabled: boolean
		defaultValue: boolean
		callback: (data: any) => void
		name: TypeSubscriptionEnum
	}
	active?: boolean
}

const Accordion: FC<IProps> = ({ children, title, status, price, checkbox, active }) => {
	const [height, setHeight] = useState(active ? 'auto' : 0)

	return (
		<div className="accordion">
			<div
				className="accordion__top"
				aria-expanded={height !== 0}
				aria-controls="example-panel"
				onClick={() => setHeight(height === 0 ? 'auto' : 0)}
			>
				<div className="accordion__title">
					{title}
					{status && <ServiceStatus status={status} />}
					{checkbox && (
						<CheckboxDefault
							text={checkbox.text}
							defaultValue={checkbox.defaultValue}
							disabled={checkbox.disabled}
							callback={checkbox.callback}
							name={checkbox.name as unknown as string}
						/>
					)}
				</div>

				<div className="react-accordion__price">
					{price && (
						<div className="costs-page__row-col costs-accordion__col">
							<div className="accordion__title">{price} ₽</div>
						</div>
					)}

					<div className={`accordion__icon ${height ? 'active' : ''}`}>
						<svg
							className="accordion__svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M8.70888 5.79116C8.61515 5.88412 8.54076 5.99472 8.48999 6.11658C8.43922 6.23844 8.41309 6.36915 8.41309 6.50116C8.41309 6.63317 8.43922 6.76388 8.48999 6.88574C8.54076 7.0076 8.61515 7.1182 8.70888 7.21116L13.2889 11.7912C13.3826 11.8841 13.457 11.9947 13.5078 12.1166C13.5585 12.2384 13.5847 12.3691 13.5847 12.5012C13.5847 12.6332 13.5585 12.7639 13.5078 12.8857C13.457 13.0076 13.3826 13.1182 13.2889 13.2112L8.70888 17.7912C8.61516 17.8841 8.54076 17.9947 8.48999 18.1166C8.43922 18.2384 8.41309 18.3691 8.41309 18.5012C8.41309 18.6332 8.43922 18.7639 8.48999 18.8857C8.54076 19.0076 8.61516 19.1182 8.70888 19.2112C8.89625 19.3974 9.1497 19.502 9.41388 19.502C9.67807 19.502 9.93152 19.3974 10.1189 19.2112L14.7089 14.6212C15.2707 14.0587 15.5862 13.2962 15.5862 12.5012C15.5862 11.7062 15.2707 10.9437 14.7089 10.3812L10.1189 5.79116C9.93152 5.60491 9.67807 5.50037 9.41388 5.50037C9.1497 5.50037 8.89625 5.60491 8.70888 5.79116Z"
								fill="#001F38"
							/>
						</svg>
					</div>
				</div>
			</div>

			<AnimateHeight id="example-panel" duration={500} height={height as number}>
				<div className="accordion__body">{children}</div>
			</AnimateHeight>
		</div>
	)
}

export default Accordion
