import { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import Panel from 'src/components/common/panel/Panel'
import Categories, { ICategory } from 'src/components/ui/categories/Categories'
import Loader from 'src/components/ui/loader/Loader'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import {
	AvailablePlans,
	Channels,
	MainSpecPlans,
	ResponseChannels,
	ResponseMainSpecPlans
} from 'src/shared/types/swagger'

import AvailableOptionsCard from '../../availableOptions/components/AvailableOptionsCard'
import SpecialOffer from '../../components/specialOffer/SpecialOffer'
import TvChannelList from '../../tv/ui/TvChannelList'
import ServicesTabs from '../components/ServicesTabs'

import './style.scss'

const ServicesTabSpec = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState<MainSpecPlans | null>(null)
	const [categories, setCategories] = useState<ICategory[]>([])
	const [currentCategory, setCurrentCategory] = useState<ICategory>()
	const [sortedData, setSortedData] = useState<AvailablePlans[] | null>()
	const [channelsData, setChannelsData] = useState<Channels | null>(null)
	const [isChannelsModalActive, setChannelsModalActive] = useState(false)

	//Первичные данные
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			try {
				const response: AxiosResponse<ResponseMainSpecPlans> = await $api.get(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/services/specpromo/`
				)

				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch VideoAvailable:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичные данные

	//Получаем список категорий
	useEffect(() => {
		if (data) {
			const arr: ICategory[] = []
			let allItems = 0
			const uniqueCategories: { [key: string]: boolean } = {}

			for (const categoryCode in data.available_plans) {
				if (data.available_plans.hasOwnProperty(categoryCode)) {
					const category = data.available_plans[categoryCode]

					if (!uniqueCategories[categoryCode] && category) {
						uniqueCategories[categoryCode] = true
						allItems += category.items?.length ?? 0
						arr.push({
							category_code: category.category_code,
							category_name: category.category_name,
							quantity: category.items?.length ?? 0
						})
					}
				}
			}
			//Получаем количество всех доступных планов

			const allItemsCategory = {
				category_code: '',
				category_name: 'Все',
				quantity: allItems
			}
			arr.unshift(allItemsCategory)
			//Получаем количество всех доступных планов

			setCategories(arr)
			setCurrentCategory(allItemsCategory)
		}
	}, [data])
	//Получаем список категорий

	// Сортировка и фильтрация
	useEffect(() => {
		if (data && data.available_plans) {
			const sortedData = Object.values(data.available_plans)
				.sort((a, b) => {
					return a!.display_order - b!.display_order
				})
				.filter(item => {
					if (currentCategory && currentCategory.category_code) {
						return item!.category_code === currentCategory.category_code
					} else {
						return true
					}
				})

			setSortedData(sortedData)
		}
	}, [data, currentCategory])
	//Сортировка и фильтрация

	//колбек для категорий
	const categoryCallback = (category: ICategory) => {
		setCurrentCategory(category)
	}
	//колбек для категорий

	//Запрос и вывод модального окна с каналами при нажатии
	const handleChannels = (key: string, serviceType: string) => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseChannels> = await $api.get(
					`${API_DOMAIN}channels/${serviceType}/${key}/`
				)
				const { data } = response.data
				console.info('channels', DataTransfer)
				setChannelsData(data)
				setChannelsModalActive(true)
			} catch (error: any) {
				console.error('Failed to fetch channels:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	//Запрос и вывод модального окна с каналами при нажатии

	return (
		<>
			<Loader state={isLoading} />
			{/* //Модальное окно вывода каналов */}
			<TvChannelList
				data={channelsData!}
				state={isChannelsModalActive}
				setState={setChannelsModalActive}
			/>
			<div className="services-page">
				<Panel text="Услуги" />

				<section className="section section--first-panel r-section-first r-services-spec">
					<div className="container">
						<ServicesTabs />

						{data && sortedData?.length ? (
							<>
								<div className="services-page__categories">
									{categories && categories.length > 1 && (
										<Categories
											data={categories}
											callback={categoryCallback}
											currentCategory={currentCategory!}
										/>
									)}
								</div>
								{/* //Специальное предложение */}
								{data.spec_offers && <SpecialOffer data={data.spec_offers} />}
								{/* //Специальное предложение */}
								<div className="services-page__list">
									{sortedData &&
										sortedData.map((item, index) => {
											if (item.is_block) {
												return (
													<div
														className="services-page__list"
														key={item.category_name}
													></div>
												)
											} else {
												return (
													<div
														className="services-page__list"
														key={item.category_name}
													>
														<div className="services-page__item">
															<div className="services-page__item-heading">
																{item.category_name}
															</div>
															<div className="services-page__item-grid">
																{item.items &&
																	item.items.map(card => (
																		<AvailableOptionsCard
																			variant="spec"
																			key={card.plan_id}
																			data={card}
																			//TODO: передаем category_code как service_type (Только тут)
																			service_type={
																				item.category_code
																			}
																			plan_id={card.plan_id}
																			handleChannels={
																				handleChannels
																			}
																		/>
																	))}
															</div>
														</div>
													</div>
												)
											}
										})}
								</div>{' '}
							</>
						) : (
							<div className="payment-page__autopay">
								<div className="payment-page__autopay-container">
									<div className="payment-page__autopay-icon">
										<svg
											className="payment-page__autopay-svg"
											width={40}
											height={40}
											viewBox="0 0 40 40"
											fill="none"
										>
											<path
												d="M32.1675 7.9698C35.0688 11.3062 36.6667 13.9119 36.6667 18.3333H33.3333C33.3333 14.7962 31.7217 13.0449 29.4007 10.3759C27.0796 7.70676 23.8722 5.96639 20.3693 5.47538C16.8664 4.98437 13.3041 5.7758 10.3387 7.70386C7.37321 9.63193 5.20444 12.5667 4.23193 15.9675C3.25942 19.3683 3.54868 23.006 5.04642 26.2104C6.54417 29.4148 9.14949 31.97 12.3824 33.4052C15.6153 34.8403 19.2579 35.0589 22.6392 34.0204C25.9094 33.0161 28.7221 30.9015 30.5951 28.0474L32.846 30.7485C30.5747 34.0843 27.6545 36.3158 23.7156 37.5255C19.489 38.8236 14.9357 38.5504 10.8946 36.7564C6.85352 34.9625 3.59687 31.7685 1.72469 27.763C-0.147487 23.7575 -0.509065 19.2104 0.706577 14.9594C1.92222 10.7084 4.63318 7.0399 8.33998 4.62982C12.0468 2.21974 16.4997 1.23045 20.8783 1.84421C25.2569 2.45798 29.2662 4.63344 32.1675 7.9698Z"
												fill="#0076D8"
											/>
											<path
												d="M34.5556 26.6667L28.3333 18.3333H40L34.5556 26.6667Z"
												fill="#0076D8"
											/>
											<path
												d="M15 15L25.5621 15C25.3761 13.8652 25.0956 12.7499 24.7242 11.6667H11.6667C10.7462 11.6667 10 12.4129 10 13.3333V25C10 25.9205 10.7462 26.6667 11.6667 26.6667H29.4444L22.5 18.3333H15C14.0795 18.3333 13.3333 17.5871 13.3333 16.6667C13.3333 15.7462 14.0795 15 15 15Z"
												fill="#0076D8"
											/>
										</svg>
									</div>
									<h3 className="section__heading">
										На данный момент нет доступных специальных предложений
									</h3>
									<div className="payment-page__autopay-text">
										Мы регулярно обновляем наши акции. Попробуйте проверить
										позже или обратитесь в контактный центр по номеру:
										<a href="tel:88125958121" style={{ whiteSpace: 'nowrap' }}>
											8 (812) 595-81-21
										</a>
									</div>
									<div className="payment-page__autopay-text">
										{/* //ссылка на условия  */}
										{/* <ConditionUi data={condition_block} /> */}
										{/* //ссылка на условия  */}
									</div>
									{/* <div className="payment-page__autopay-btn">
										<Button
											text={'Подключить автоплатеж'}
											// href={PAYMENTCONNECTAUTO}
										/>
									</div> */}
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		</>
	)
}

export default ServicesTabSpec
