import { FC } from 'react'

import { ServiceBlockInfo } from 'src/shared/types/swagger'

import './ServiceInfoBlock.scss'

interface IProps {
	data: ServiceBlockInfo
}

const ServiceBlockInfoUi: FC<IProps> = ({ data }) => {
	return (
		<div className="connected-page__wrapper react-service-info">
			<div className="connected-page__accordion-heading">{data.title}</div>
			<div className="connected-page__inner">
				<div className="connected-page__row react-service-info__row">
					<div className="connected-page__row-col">
						<div className="services-page__card-label">Кем запланирован</div>
						<div className="connected-page__text">{data.who_block}</div>
					</div>

					<div className="connected-page__row-col">
						<div className="connected-page__row-item">
							<div className="services-page__card-label">Статус</div>
							<div className="connected-page__text">{data.status}</div>
						</div>
						<div className="connected-page__row-item">
							<div className="services-page__card-label">Дата начала</div>
							<div className="connected-page__text">{data.block_start_date}</div>
						</div>
						<div className="connected-page__row-item">
							<div className="services-page__card-label">Дата окончания</div>
							<div className="connected-page__text">{data.block_end_date}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ServiceBlockInfoUi
