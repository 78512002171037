import React, { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import Panel from 'src/components/common/panel/Panel'
import Loader from 'src/components/ui/loader/Loader'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import useModal from 'src/shared/hooks/useModal'
import { ResponseServicesClient, ServicesClient } from 'src/shared/types/swagger'

import ServicePreviewCard from '../../components/previewCard/ServicePreviewCard'
import ServicesTabs from '../components/ServicesTabs'

const ServiceTabConnected = () => {
	const [serviceOrderKeys, setServiceOrderKeys] = useState<string[] | null>(null)
	const [serviceList, setServiceList] = useState<Omit<ServicesClient, 'service_order'> | null>(
		null
	)
	const [isLoading, setIsLoading] = useState(true)
	const { open } = useModal()
	//Первичный запрос
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServicesClient> = await $api.get(
					`${API_DOMAIN}clients/${await getFromLocalStorage('agreement')}/services/general/`
				)

				const { data } = response.data
				const { service_order, ...services } = data
				setServiceOrderKeys(Object.values(service_order) as string[])
				setServiceList(services as Omit<ServicesClient, 'service_order'>)
			} catch (error: any) {
				console.error('Failed to fetch client:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичный запрос

	//Лоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}
	//Лоадер
	return (
		serviceOrderKeys && (
			<div className="services-page">
				<Panel text="Услуги" />
				<section className="section section--first-panel r-section-first">
					<div className="container">
						<ServicesTabs />
						<div className="services-page__inner">
							<div className="services-page__block active">
								<div className="service-grid">
									{serviceOrderKeys.map(key => {
										// @ts-ignore
										if (!serviceList[key]) {
											return
										}
										// @ts-ignore
										return serviceList[key].map(service => (
											<ServicePreviewCard
												data={service}
												key={service.service_cid}
											/>
										))
									})}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	)
}

export default ServiceTabConnected
