import React, { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Panel from 'src/components/common/panel/Panel'
import Loader from 'src/components/ui/loader/Loader'
import { SERVICES } from 'src/routes/data'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import { ResponseServiceTel, ServiceTel } from 'src/shared/types/swagger'

import ServiceAvailableOptions from '../../availableOptions/ServiceAvailableOptions'
import TelephonyConnected from '../components/connected/TelephonyConnected'

const TelephonySettings: FC = () => {
	const { id } = useParams()
	const [data, setData] = useState<ServiceTel | null>(null)
	const [isLoading, setIsLoading] = useState(true)

	//Первичные данные
	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServiceTel> = await $api.get(
					`${API_DOMAIN}services/tel/${id}/`
				)
				const { data } = response.data

				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch tv:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [])
	//Первичные данные

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		data && (
			<div className="services-page connected-page">
				<Panel text="Подключенные" linkText="Услуги" href={SERVICES} />
				<section className="section section--first-panel r-panel-tubs">
					<div className="container">
						<Tabs>
							<div className="main-page__services-top">
								<TabList>
									<div className="sections">
										<div className="sections__list">
											<Tab
												className="sections__tab"
												selectedClassName="active"
											>
												Мои опции
											</Tab>
											<Tab
												className="sections__tab"
												selectedClassName="active"
											>
												Доступные опции
											</Tab>
										</div>
									</div>
								</TabList>
							</div>
							<TabPanel>
								<TelephonyConnected data={data} />
							</TabPanel>
							<TabPanel>
								<ServiceAvailableOptions service_cid={data.service_cid} />
							</TabPanel>
						</Tabs>
					</div>
				</section>
			</div>
		)
	)
}

export default TelephonySettings
