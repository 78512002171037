import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AxiosResponse } from 'axios'
import {
	getFromLocalStorage,
	removeFromLocalStorage
} from 'src/shared/api/service/helpers/localStorage.helper'
import {
	AuthRefreshRequest,
	Banner,
	BaseResponse,
	ContactsResponse,
	ResponseBanners,
	ResponseContactsResponse
} from 'src/shared/types/swagger'

import { API_DOMAIN } from '../../shared/api/service/endpoints'
import $api from '../../shared/api/service/request'
import { initialStore } from '../_initial'

export const asyncSidebarBanners = createAsyncThunk<Banner[], void, { rejectValue: string }>(
	'promotions/banners/',
	async (_, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<ResponseBanners> = await $api.get(
				`${API_DOMAIN}promotions/banners/`
			)
			return response.data.data.banners
		} catch (error: any) {
			return rejectWithValue(error.message || 'Failed to fetch banners')
		}
	}
)

export const asyncContacts = createAsyncThunk<ContactsResponse, void, { rejectValue: string }>(
	'promotions/contacts/',
	async (_, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<ResponseContactsResponse> = await $api.get(
				`${API_DOMAIN}promotions/contacts/`
			)
			return response.data.data
		} catch (error: any) {
			return rejectWithValue(error.message || 'Failed to fetch contacts')
		}
	}
)
export const asyncLogout = createAsyncThunk<any, void, { rejectValue: string }>(
	'auth/logout/',
	async (_, { rejectWithValue, dispatch }) => {
		const body: AuthRefreshRequest = {
			refresh: getFromLocalStorage('refresh'),
			login: getFromLocalStorage('agreement')
		}

		dispatch(loaderOn())
		try {
			const response = await $api.post(`${API_DOMAIN}auth/logout/`, body)
			return response
		} catch (error: any) {
			return rejectWithValue(error.message || 'Failed to fetch contacts')
		}
	}
)

export const clientSlice = createSlice({
	name: 'app',
	initialState: initialStore,
	reducers: {
		clearBannersState: state => {
			state.banners = initialStore.banners
		},

		clearContactsState: state => {
			state.contacts = initialStore.contacts
		},

		clearStore: state => {
			state = initialStore
		},

		loggedIn: state => {
			state.app.isAuth = true
		},

		logout: () => {
			// window.location.href = '/login'
			removeFromLocalStorage('token')
			removeFromLocalStorage('refresh')
			removeFromLocalStorage('agreement')
		},

		loaderOn: state => {
			state.app.isLoaderActive = true
		},
		loaderOff: state => {
			state.app.isLoaderActive = false
		}
	},

	extraReducers: builder => {
		// ##########################################################
		// Рекламные баннеры
		builder
			.addCase(asyncSidebarBanners.pending, state => {
				state.banners.loading = true
			})
			.addCase(asyncSidebarBanners.fulfilled, (state, action) => {
				state.banners.data = action.payload
				state.banners.loading = false
			})
			.addCase(asyncSidebarBanners.rejected, state => {
				state.banners.loading = false
			})
		// ##########################################################
		// контакты
		builder
			.addCase(asyncContacts.pending, state => {
				state.contacts.loading = true
			})
			.addCase(asyncContacts.fulfilled, (state, action) => {
				state.contacts.data = action.payload
				state.contacts.loading = false
			})
			.addCase(asyncContacts.rejected, state => {
				state.contacts.loading = false
			})
		// ##########################################################
		// разлогирование
		builder
			.addCase(asyncLogout.pending, state => {
				state.logout.loading = true
			})
			.addCase(asyncLogout.fulfilled, (state, action) => {
				state.logout.loading = false
			})
			.addCase(asyncLogout.rejected, state => {
				state.logout.loading = false
			})
	}
})

export const {
	clearBannersState,
	clearContactsState,
	logout,
	loggedIn,
	clearStore,
	loaderOff,
	loaderOn
} = clientSlice.actions
export default clientSlice.reducer
