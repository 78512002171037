import { FC, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Loader from 'src/components/ui/loader/Loader'
import SelectUi, { ISelectOptions } from 'src/components/ui/select/SelectUi'
import { getDocuments } from 'src/entitties/documents/api/getDocuments'
import { Document, GroupedDocument, YearlyDocuments } from 'src/entitties/documents/model/type'
import { setFilterCategory } from 'src/redux/slices/notificationsSlice'
import { PROFILE } from 'src/routes/data'

import Panel from '../../panel/Panel'

import './ProfileDocuments.scss'
import ProfileDocumentsCard from './components/ProfileDocumentsCard'
import ProfileDocumentsTab from './components/ProfileDocumentsTab'

const ProfileDocuments: FC = () => {
	const [isLoading, setIsLoading] = useState(true)

	const [contractList, setContractList] = useState<YearlyDocuments[] | null>()
	const [othersList, setOthersList] = useState<YearlyDocuments[] | null>([])

	// Первичные данные
	useEffect(() => {
		;(async () => {
			setIsLoading(true)
			const res = await getDocuments()
			setIsLoading(false)

			if (res) {
				setContractList(res.contracts)
				setOthersList(res.others)
			}
		})()
	}, [])

	// Лоадер
	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		<div className="profile-page">
			<Panel text="Сопутствующие документы" href={PROFILE} linkText="Управление профилем" />
			<section className="section section--first-panel">
				<div className="container">
					<Tabs>
						<div className="profile-page__tabs">
							<div className="sections">
								<TabList className="sections__list">
									<Tab className="sections__tab" selectedClassName="active">
										Основные
									</Tab>
									<Tab className="sections__tab" selectedClassName="active">
										Прочие
									</Tab>
								</TabList>
							</div>
						</div>
						<div className="profile-page__inner">
							<TabPanel>
								<ProfileDocumentsTab data={contractList} />
							</TabPanel>
							<TabPanel>
								<ProfileDocumentsTab data={othersList} />
							</TabPanel>
						</div>
					</Tabs>
				</div>
			</section>
		</div>
	)
}

export default ProfileDocuments
