import { FC, useEffect, useState } from 'react'

import Svg from 'src/components/ui/svg/Svg'
import { AtvGroup, ServiceDtvOtt, ServiceTv } from 'src/shared/types/swagger'

import TvAtv from '../components/atv/TvAtv'
import TvDtv from '../components/dtv/TvDtv'
import TvOtt from '../components/ott/TvOtt'

import './TvSettingConnected.scss'

interface IProps {
	data: ServiceTv
}
const TvSettingConnected: FC<IProps> = ({ data }) => {
	const [serviceOrderKeys, setServiceOrderKeys] = useState<
		('atv' | 'dtv' | 'ott' | 'collective_atv')[] | null
	>(null)
	const [tvData, setTvData] = useState<Omit<
		ServiceTv,
		| 'service_order'
		| 'focus_service_cid'
		| 'focus_service'
		| 'common_agreement'
		| 'is_common_agreement'
	> | null>(null)

	const [focusService, isFocusService] = useState<string[] | undefined>(undefined)

	//деструктуризация данных при получении, выделение услуги для фокуса на нее
	useEffect(() => {
		const {
			service_order,
			focus_service,
			focus_service_cid,
			common_agreement,
			is_common_agreement,
			...services
		} = data
		setServiceOrderKeys(Object.values(service_order))
		setTvData(services)

		//Установление фокусируемой услуги при загрузке
		const focusServiceCurrent = (): string[] | undefined => {
			if (data?.focus_service) {
				return data.focus_service?.services?.map((item, index) => {
					if (
						(item as ServiceDtvOtt).service_type === 'atv' ||
						(item as ServiceDtvOtt).service_type === 'collective_atv'
					) {
						return 'atv'
					} else if ((item as ServiceDtvOtt).service_type === 'dtv') {
						return 'dtv'
					} else {
						return 'ott'
					}
				})
			}
			return undefined
		}
		//Установление фокусируемой услуги при загрузке

		isFocusService(focusServiceCurrent())
	}, [data])
	//деструктуризация данных при получении, выделение услуги для фокуса на нее

	return (
		tvData &&
		serviceOrderKeys && (
			<>
				{
					focusService && (
						//фокус сервис
						<div className="services-page__block active r-services-page__target react-line">
							{focusService.map(focusService => {
								if (focusService === 'atv' || focusService === 'collective_atv') {
									return (
										<TvAtv
											data={data?.focus_service as AtvGroup}
											key={focusService}
										/>
									)
								} else if (focusService === 'dtv') {
									return (
										<div
											className="connected-page__block tv-block"
											key={focusService}
										>
											<div className="connected-page__icon">
												<Svg type={'atv'} />
											</div>
											<div className="connected-page__inner">
												<h3 className="section__title">
													{data.focus_service.service_title}
												</h3>
											</div>
											{data?.focus_service?.services?.map(dtv => (
												<TvDtv
													key={focusService}
													data={dtv as ServiceDtvOtt}
													accordionActive={true}
												/>
											))}
										</div>
									)
								} else
									return (
										<div
											className="connected-page__block tv-block"
											key={focusService}
										>
											<div className="connected-page__icon">
												<Svg type={'atv'} />
											</div>
											<div className="connected-page__inner">
												<h3 className="section__title">
													{data.focus_service.service_title}
												</h3>
											</div>
											{data?.focus_service?.services?.map((dtv, index) => (
												<TvOtt
													key={focusService + index}
													data={dtv as ServiceDtvOtt}
												/>
											))}
										</div>
									)
							})}
						</div>
					)
					//фокус сервис
				}

				<div className="services-page__block active">
					<div className="connected-page__container r-connected-page__container">
						{serviceOrderKeys.map((key, index) => {
							if (!tvData[key]) return

							if (key === 'atv' || key === 'collective_atv') {
								return (
									<TvAtv
										key={key + index}
										data={tvData[key] as AtvGroup}
										isCommonAgreement={data.is_common_agreement}
										commonAgreement={data.common_agreement}
									/>
								)
							} else if (key === 'dtv') {
								return (
									<div
										className="connected-page__block tv-block"
										key={key + index}
									>
										<div className="connected-page__icon">
											<Svg type={'atv'} />
										</div>
										<div className="connected-page__inner">
											<h3 className="section__title">
												{tvData[key]?.service_title}
											</h3>
										</div>
										{tvData[key]?.services!.map((dtv, index) => (
											<TvDtv key={dtv.service_cid} data={dtv} />
										))}
									</div>
								)
							} else {
								return (
									<div
										className="connected-page__block tv-block"
										key={key + index}
									>
										<div className="connected-page__icon">
											<Svg type={'iTv'} />
										</div>
										<div className="connected-page__inner">
											<h3 className="section__title">
												{tvData[key]?.service_title}
											</h3>
										</div>
										{tvData[key]?.services?.map((dtv, index) => (
											<TvOtt key={dtv.service_cid} data={dtv} />
										))}
									</div>
								)
							}
						})}
					</div>
				</div>
			</>
		)
	)
}

export default TvSettingConnected
