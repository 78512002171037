import {
	INTERNETSETTINGS,
	RADIOSETTINGS,
	SERVICES,
	TELEPHONYSETTINGS,
	TVSETTINGS,
	UDOMOFONSETTINGS,
	VIDEOSETTINGS
} from 'src/routes/data'
import { ServiceTypeEnum } from 'src/shared/types/swagger'

export const linkOfType = (type: ServiceTypeEnum, id: string) => {
	switch (type) {
		case ServiceTypeEnum.Eth:
			return INTERNETSETTINGS + '/' + id + '/' + type

		case ServiceTypeEnum.Pon:
			return INTERNETSETTINGS + '/' + id + '/' + type

		case ServiceTypeEnum.Docsis:
			return INTERNETSETTINGS + '/' + id + '/' + type

		case ServiceTypeEnum.Atv:
			return TVSETTINGS + '/' + id

		case ServiceTypeEnum.Dtv:
			return TVSETTINGS + '/' + id

		case ServiceTypeEnum.Ott:
			return TVSETTINGS + '/' + id

		case ServiceTypeEnum.CollectiveAtv:
			return TVSETTINGS + '/' + id

		case ServiceTypeEnum.Rec:
			return VIDEOSETTINGS + '/' + id

		case ServiceTypeEnum.UDomofon:
			return UDOMOFONSETTINGS + '/' + id + '/' + type

		case ServiceTypeEnum.CollectiveDomofon:
			return UDOMOFONSETTINGS + '/' + id + '/' + type

		case ServiceTypeEnum.Tel:
			return TELEPHONYSETTINGS + '/' + id

		case ServiceTypeEnum.CollectiveRadio:
			return RADIOSETTINGS + '/' + id

		default:
			return SERVICES
	}
}
