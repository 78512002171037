/**
 * Конвертирует строку base64 в объект Blob
 * @param base64Data - Строка в формате base64
 * @param mimeType - MIME-тип данных (по умолчанию application/octet-stream)
 * @returns Blob объект
 */
export const convertBase64ToBlob = (
	base64Data: string,
	mimeType: string = 'application/octet-stream'
): Blob => {
	const base64String = base64Data.split(',').pop() || ''
	const byteCharacters = atob(base64String)
	const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0))
	const byteArray = new Uint8Array(byteNumbers)
	return new Blob([byteArray], { type: mimeType })
}
