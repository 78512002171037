import { AxiosResponse } from 'axios'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'

import { GroupedDocument, ResponseGroupedDocument } from '../model/type'

export const getDocuments = async (): Promise<GroupedDocument> => {
	const res: AxiosResponse<ResponseGroupedDocument> = await $api.get(
		`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/documents/`
	)

	return res.data.data
}
