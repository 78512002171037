import { FC, useEffect } from 'react'

import { asyncSidebarBanners } from 'src/redux/slices/appSlice'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import { useAppDispatch, useAppSelector } from 'src/shared/hooks/redusHooks'

interface IProps {}
const SidebarBanners: FC<IProps> = () => {
	const {
		banners: { data, loading }
	} = useAppSelector(state => state.app)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (!data && !loading && getFromLocalStorage('token')) {
			dispatch(asyncSidebarBanners())
		}
	}, [loading, dispatch])

	return (
		data && (
			<div className="sidebar__bot">
				{data.map(item => (
					<a
						className="sidebar__block"
						target="blank"
						href={item.redirect_url}
						key={item.title}
					>
						<div className="sidebar__block-item">
							<div className="sidebar__block-title">{item.title}</div>
							<div className="sidebar__block-text">{item.description}</div>
						</div>
						<div className="sidebar__block-item">
							<div className="sidebar__block-icon">
								<svg
									className="sidebar__block-svg"
									width={24}
									height={24}
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5 5H18C18.5523 5 19 5.44772 19 6V19M18 6L4 20"
										stroke="#EC003E"
										strokeWidth={2}
										strokeLinecap="round"
									/>
								</svg>
							</div>
						</div>
					</a>
				))}
			</div>
		)
	)
}

export default SidebarBanners
