import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import Button from 'src/components/ui/button/Button'
import CheckboxDefault from 'src/components/ui/checkbox/CheckboxDefault'
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi'
import Loader from 'src/components/ui/loader/Loader'
import Modal from 'src/components/ui/modal/Modal'
import { CONDITIONSPAGE } from 'src/routes/data'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import useModal from 'src/shared/hooks/useModal'
import { Condition } from 'src/shared/types/Condition'
import { Channels, PacketDtvOtt, ResponseChannels, ServiceTypeEnum } from 'src/shared/types/swagger'
import { paymentDisplay } from 'src/shared/utils/paymentDisplay'

import TvChannelList from '../../../ui/TvChannelList'

import './TvChangePacket.scss'

interface IProps {
	data: PacketDtvOtt[]
	state: boolean
	setState: (prev: any) => void
	serviceCid: string
	serviceType: ServiceTypeEnum
	condition_block?: Condition | null
	title: string
}

interface ISelectedPackages {
	key: string
	plan_id: number
	cost: number
}

const TvChangePacket: FC<IProps> = ({
	state,
	setState,
	data,
	serviceCid,
	condition_block,
	serviceType,
	title
}) => {
	const [selectedPackets, setSelectedPackets] = useState<ISelectedPackages[] | []>([])
	const [sum, setSum] = useState<number | null>(null)
	const [isChannelsModalActive, setChannelsModalActive] = useState(false)
	const [channelsData, setChannelsData] = useState<Channels | null>(null)
	const { open } = useModal()

	//Лоадер
	const [isLoading, setIsLoading] = useState(false)
	const [isLoaderTransparent, setIsLoaderTransparent] = useState(false)
	//Лоадер

	const [isLoadingButton, setIsLoadingButton] = useState(false)
	const [isPacketModalActive, setPacketModalActive] = useState(false)

	//Функция для закрытия модального окна с пакетами
	const handleExit = () => {
		setPacketModalActive(false)
		setTimeout(() => {
			setState((prev: boolean) => !prev)
		}, 600)
	}
	//Функция для закрытия модального окна с пакетами

	//Открытие модального окна при заходе на страницу
	useEffect(() => {
		if (state) {
			setPacketModalActive(true)
		}
	}, [state])
	//Открытие модального окна при заходе на страницу

	//Запрос и вывод модального окна с каналами при нажатии
	const handleChannelsModalActive = (key: string) => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseChannels> = await $api.get(
					`${API_DOMAIN}channels/${serviceType}/${key}/`
				)
				const { data } = response.data
				setChannelsData(data)
				setChannelsModalActive(true)
			} catch (error: any) {
				console.error('Failed to fetch channels:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	//Запрос и вывод модального окна с каналами при нажатии

	//Обработка данных при заходе на выбранные пакеты
	useEffect(() => {
		const newArr: ISelectedPackages[] = []
		data.map(item => {
			if (item.packet_set === true) {
				newArr.push({
					key: item.key,
					plan_id: item.plan_id,
					cost: item.plan_fee
				})
			}
		})
		setSelectedPackets(newArr)
	}, [data])
	//Обработка данных при заходе на выбранные пакеты

	//Обработка суммы
	useEffect(() => {
		//@ts-ignore
		const newSum = selectedPackets.reduce((acc: number, item: ISelectedPackages) => {
			if (item.cost !== undefined) {
				return acc + item.cost
			}
			return acc
		}, 0)

		setSum(newSum)
	}, [selectedPackets])
	//Обработка суммы

	//колбек для изменения чекбокса
	const handleCheckboxChange = (props: PacketDtvOtt) => {
		const { key, plan_id, plan_fee: cost } = props
		const newPacket = { key, plan_id, cost }

		const isSelected = selectedPackets.some(item => item.plan_id === plan_id)

		if (isSelected) {
			setSelectedPackets(selectedPackets.filter(item => item.plan_id !== plan_id))
		} else {
			setSelectedPackets([...selectedPackets, newPacket])
		}
	}
	//колбек для изменения чекбокса

	//Отправка формы для изменения пакетов
	const onSubmit = async () => {
		setIsLoadingButton(prev => !prev)
		handleLoader(true, true)

		const newData = selectedPackets.map(item => {
			const { cost, ...rest } = item
			return item
		})
		try {
			const response = await $api.put(
				`${API_DOMAIN}services/${serviceType}/${serviceCid}/subscription/`,
				{
					packets: [...newData]
				}
			)

			open({
				text:
					response?.data?.data?.title ||
					response?.data?.client_message ||
					'Изменения сохранены',
				modalType: 'tv',
				isReload: true,
				description: response?.data?.data?.description || ''
			})
		} catch (error: any) {
			console.info('error', error)
			open({
				text:
					error.response?.data?.data?.title ||
					error.response.data.client_message ||
					'Ошибка',
				description: error.response?.data?.data?.description || '',
				modalType: 'error'
			})
		} finally {
			handleExit()
			setIsLoading(false)
			handleLoader(false, false)
		}
	}
	//Отправка формы для изменения пакетов

	//Обработчик лоадера
	const handleLoader = (state: boolean, isTransparent?: boolean) => {
		setIsLoading(state)
		setIsLoaderTransparent(isTransparent || false)
	}
	//Обработчик лоадера

	return (
		data && (
			<>
				<Loader state={isLoading} isTransparent={isLoaderTransparent} />
				{/* //вывод пакетов */}
				<Modal state={isPacketModalActive} setState={handleExit}>
					<div
						className="modules-modal modal fancybox-content react-tvChangePacket"
						data-fancy-modal="modules"
						data-tv-modal=""
						style={{ display: 'inline-block' }}
					>
						<div className="modules-modal__container">
							<div className="modules-modal__title">{title}</div>
							<div className="modules-modal__grid">
								{data.map(item => (
									<div className="modules-modal__item" key={item.key}>
										<div className="modules-modal__item-icon">
											{item.is_highlighted && (
												<svg
													className="modules-modal__item-svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
												>
													<path
														d="M15 17.5973C15 16.2348 14.1743 14.3727 12.6776 12.3534C12.5947 12.2413 12.491 12.1515 12.3738 12.0904C12.2567 12.0293 12.129 11.9984 12.0001 12.0001C11.8702 11.9994 11.7417 12.0308 11.6236 12.0921C11.5055 12.1535 11.4006 12.2433 11.3162 12.3552C9.67766 14.5325 8.87682 16.4582 9.01537 17.9343C9.06989 18.401 9.21098 18.8493 9.42936 19.2497C9.64773 19.65 9.93843 19.9933 10.2823 20.257C10.7687 20.7094 11.3716 20.9701 12.0001 21C12.7954 20.999 13.558 20.6402 14.1204 20.0023C14.6828 19.3644 14.9992 18.4995 15 17.5973Z"
														fill="#EC003E"
													/>
													<path
														d="M16.1728 3.65791C15.4639 3.04533 14.7199 2.40341 13.9623 1.70279C13.675 1.43761 13.3342 1.23948 12.9638 1.12226C12.5933 1.00504 12.2021 0.971569 11.8176 1.02419C11.4449 1.07437 11.0876 1.20715 10.7709 1.41315C10.4543 1.61914 10.1859 1.89333 9.98493 2.21633C8.91022 4.04291 8.15013 6.04265 7.73741 8.12937C7.57593 7.89163 7.43242 7.64179 7.3081 7.38199C7.18045 7.10851 6.9882 6.87142 6.74852 6.69185C6.50884 6.51229 6.22917 6.39584 5.93447 6.35289C5.63977 6.30994 5.33919 6.34182 5.05955 6.4457C4.77991 6.54958 4.52991 6.72223 4.33185 6.94823C2.81964 8.52039 1.98138 10.6375 2.00044 12.8365C1.97953 15.0827 2.698 17.2715 4.04154 19.0547C5.38508 20.8379 7.27651 22.113 9.41493 22.6772C10.2337 22.8867 11.0743 22.9951 11.9186 23C10.8397 22.9591 9.80268 22.5648 8.96218 21.8757C8.37992 21.459 7.89034 20.9225 7.52521 20.301C7.16008 19.6795 6.92755 18.9869 6.84272 18.2681C6.63529 16.2415 7.64812 13.8819 9.85325 11.2555C10.1178 10.9402 10.4467 10.6872 10.817 10.5142C11.1874 10.3411 11.5904 10.252 11.998 10.2532H12.007C12.4134 10.2536 12.8149 10.344 13.1834 10.5182C13.5519 10.6924 13.8787 10.9462 14.1409 11.262C15.528 12.9374 17.183 15.4207 17.183 17.7371C17.181 19.0721 16.681 20.3565 15.7839 21.3313C14.8869 22.306 13.6596 22.8984 12.3497 22.989C14.9396 22.9042 17.3954 21.7975 19.1967 19.9035C20.998 18.0094 22.0034 15.4768 22 12.842C21.9955 8.68784 19.297 6.35858 16.1728 3.65791Z"
														fill="#EC003E"
													/>
												</svg>
											)}
										</div>
										<div className="modules-modal__top">
											<CheckboxDefault
												text={item.packet_name}
												defaultValue={item.packet_set}
												disabled={
													!item.packet_set && !item.can_change_packet
												}
												isBlocked={
													!item.can_change_packet && item.packet_set
												}
												callback={() => handleCheckboxChange(item)}
											/>
										</div>
										<div className="modules-modal__inner">
											<div
												className="modules-modal__link blue"
												onClick={() => handleChannelsModalActive(item.key)}
											>
												Список каналов
											</div>
											<div className="modules-modal__price">
												<div className="connected-page__text ">
													{paymentDisplay(
														item.plan_fee,
														item.fee_period,
														item.fee_kind
													)}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
							<div className="modules-modal__bot">
								<div className="connected-page__bot">
									<div className="connected-page__bot-col">
										<div className="connected-page__bot-item">
											<div className="connected-page__btn">
												<Button
													text={'Сохранить'}
													size={'small'}
													onClick={onSubmit}
													isLoading={isLoadingButton}
												/>
											</div>
										</div>
										<div className="connected-page__bot-item">
											<div className="connected-page__btn">
												<Button
													text={'Отменить'}
													theme={'outline'}
													size={'small'}
													onClick={handleExit}
												/>
											</div>
										</div>
									</div>
									<div className="connected-page__bot-col">
										<div className="services-page__card-label">
											Итоговая стоимость
										</div>

										<div className="connected-page__heading">
											{sum || 0} ₽/мес
										</div>
									</div>
								</div>
							</div>
							{/* //ссылка на условия  */}
							<ConditionUi data={condition_block} />
							{/* //ссылка на условия  */}
						</div>
						<div className="modal__icon" data-fancybox-close="" onClick={handleExit}>
							<svg
								className="modal__svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
									fill="#001F38"
								/>
							</svg>
						</div>
						<button
							type="button"
							data-fancybox-close=""
							className="fancybox-button fancybox-close-small"
							title="Close"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
							</svg>
						</button>
					</div>
				</Modal>
				{/* //вывод пакетов isLoaderTransparent*/}

				{/* //Модальное окно вывода каналов */}
				<TvChannelList
					data={channelsData!}
					state={isChannelsModalActive}
					setState={setChannelsModalActive}
				/>
				{/* //Модальное окно вывода каналов */}
			</>
		)
	)
}

export default TvChangePacket
