import { FC, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { Link, useParams } from 'react-router-dom'
import Button from 'src/components/ui/button/Button'
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi'
import Loader from 'src/components/ui/loader/Loader'
import ResponsePage from 'src/components/ui/responsePage/ResponsePage'
import { IModalData } from 'src/redux/slices/modalSlice'
import { CONDITIONSPAGE, SERVICES } from 'src/routes/data'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import {
	BlockServiceGet,
	ResponseBlockServiceGet,
	UnblockServiceRequest
} from 'src/shared/types/swagger'

import Panel from '../../../panel/Panel'

const ServiceUnblock: FC = () => {
	const { service_cid } = useParams()
	const [data, setData] = useState<BlockServiceGet | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [modalData, setModalData] = useState<IModalData | null>(null)

	const handleModal = (data: IModalData) => {
		setModalData(data)
	}

	//Первичные данные

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseBlockServiceGet> = await $api.get(
					`${API_DOMAIN}services/${service_cid}/unblock/`
				)

				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch serviceUnblock:', error.message)
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}, [service_cid])
	// Первичные данные

	// Отправка на возобновление
	const onSubmit = () => {
		setIsLoading(prev => !prev)

		const body: UnblockServiceRequest = {
			connection_address: data!.connection_address,
			full_name: data!.full_name!,
			contact_phone: data!.contact_phone
		}

		const fetchData = async () => {
			try {
				const response = await $api.put(
					`${API_DOMAIN}services/${service_cid}/unblock/`,
					body
				)
				const { data } = response

				handleModal({
					text: data?.data?.title || data?.client_message || 'Изменения сохранены',
					modalType: 'success',
					isReload: true,
					description: data?.data?.description || ''
				})
			} catch (error: any) {
				handleModal({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	//Отправка на возобновление

	if (modalData) {
		return <ResponsePage data={modalData} />
	}
	return (
		<>
			{data && !modalData && (
				<div className="services-page connected-page connected-page--default">
					<Panel text="Заявление на возобновление" linkText="Услуги" href={SERVICES} />
					<section className="section section--first-panel">
						<div className="container">
							<form className="section__container" onSubmit={onSubmit}>
								<div className="services-page__delay">
									<h1 className="section__title">
										Заявление на&nbsp;возобновление
									</h1>
									<div className="connected-page__inner">
										<div className="connected-page__accordion-heading">
											Данные абонента
										</div>
										<div className="connected-page__inner">
											<div className="services-page__delay-wrapper">
												<div className="services-page__delay-inner">
													<div className="services-page__delay-item">
														<div className="services-page__card-label">
															Услуга
														</div>
														<div className="connected-page__text">
															{data.service_title}
														</div>
													</div>
												</div>
												<div className="services-page__delay-inner connected-page__inner">
													<div className="services-page__delay-item">
														<div className="services-page__card-label">
															ФИО
														</div>
														<div className="connected-page__text">
															{data.full_name}
														</div>
													</div>
													<div className="services-page__delay-item">
														<div className="services-page__card-label">
															Номер договора
														</div>
														<div className="connected-page__text">
															{data.agreement}
														</div>
													</div>
													<div className="services-page__delay-item">
														<div className="services-page__card-label">
															Номер телефона
														</div>
														<div className="connected-page__text">
															{data.contact_phone}
														</div>
													</div>
													<div className="services-page__delay-item">
														<div className="services-page__card-label">
															Адрес подключения
														</div>
														<div className="connected-page__text">
															{data.connection_address}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="connected-page__inner">
										<div className="services-page__delay-bot">
											<div className="services-page__delay-btn">
												<Button
													text="Отменить"
													theme="outline"
													size="small"
													href={SERVICES}
												/>
											</div>
											<div className="services-page__delay-btn">
												<Button
													text="Отправить"
													size="small"
													onClick={onSubmit}
												/>
											</div>
										</div>

										{/* //ссылка на условия  */}
										<ConditionUi data={data.condition_block} />
										{/* //ссылка на условия  */}
									</div>
								</div>
							</form>
						</div>
					</section>
				</div>
			)}
			<Loader state={isLoading} />;
		</>
	)
}

export default ServiceUnblock
