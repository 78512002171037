import { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import Loader from 'src/components/ui/loader/Loader'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import $api from 'src/shared/api/service/request'
import { ResponseServiceCondition, ServiceCondition } from 'src/shared/types/swagger'

import './Conditions.scss'

const Conditions = () => {
	const { id } = useParams()
	const [data, setData] = useState<ServiceCondition | null>(null)
	const [isLoading, setLoading] = useState(true)
	//первичное получение данных
	useEffect(() => {
		document.body.style.overflow = 'auto'
		setLoading(true)
		const fetchData = async () => {
			try {
				const response: AxiosResponse<ResponseServiceCondition> = await $api.get(
					`${API_DOMAIN}promotions/service_conditions/${id}/`
				)

				const { data } = response.data
				if (data) {
					setData(data)
				}
			} catch (error: any) {
				console.error('Failed to fetch video:', error.message)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [])
	//первичное получение данных

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return data && data.text ? (
		<div className="conditions-page r-conditions-page">
			<section className="conditions-page__section">
				<div className="container">
					<div className="conditions-page__content">
						<div className="conditions-page__content react-conditions-content">
							{data.text_type === ServiceCondition.TextTypeEnum.Plain ? (
								<p>{data.text}</p>
							) : (
								<div dangerouslySetInnerHTML={{ __html: data.text }} />
							)}
						</div>
					</div>
				</div>
			</section>
		</div>
	) : null
}

export default Conditions
