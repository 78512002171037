import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Panel from 'src/components/common/panel/Panel'
import Button from 'src/components/ui/button/Button'
import Loader from 'src/components/ui/loader/Loader'
import { asyncNews, setTargetNews } from 'src/redux/slices/newsSlice'
import { NEWSDETAIL } from 'src/routes/data'
import { useAppDispatch, useAppSelector } from 'src/shared/hooks/redusHooks'
import { NewsItem } from 'src/shared/types/swagger'

const News = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [visibleCount, setVisibleCount] = useState(6)
	const { data } = useAppSelector(state => state.news)

	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (!data)
			(async () => {
				setIsLoading(true)
				await dispatch(asyncNews())
				setIsLoading(false)
			})()
	}, [])

	const handleNewsClick = (item: NewsItem) => {
		dispatch(setTargetNews(item))
		navigate(NEWSDETAIL)
	}

	const handleButtonClick = () => {
		setVisibleCount(prevCount => prevCount + 6)
	}

	if (isLoading) {
		return <Loader state={true} />
	}

	return (
		data && (
			<div className="news-page">
				<Panel text={'Новости'} />
				<section className="section section--first-panel">
					<div className="container">
						<h1 className="section__title">Новости</h1>
						<div className="news-page__grid">
							{data.slice(0, visibleCount).map((news, index) => (
								<div
									className="news-page__card"
									key={index}
									onClick={() => handleNewsClick(news)}
								>
									<div className="news-page__card-text">{news.title}</div>
									<div className="news-page__card-label">{news.published}</div>
								</div>
							))}
						</div>

						{visibleCount < data.length && (
							<div className="show-more">
								<div className="show-more__button">
									<button
										className="button button--outline button--small"
										onClick={handleButtonClick}
									>
										Показать еще
									</button>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		)
	)
}

export default News
