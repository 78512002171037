import { FC } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import SidebarRight from 'src/components/common/sidebarRight/SidebarRight'
import { NOTIFICATION } from 'src/routes/data'
import { useAppSelector } from 'src/shared/hooks/redusHooks'

import './NotificationsSidebar.scss'
import NotificationItem from './components/notificationItem/NotificationItem'

interface IProps {
	isActive: boolean
	setState: (prev: any) => void
}
const NotificationsSidebar: FC<IProps> = ({ isActive, setState }) => {
	const { newMessages: notificationList } = useAppSelector(state => state.notifications)
	const navigate = useNavigate()

	const handleClickLink = () => {
		onClose()
		navigate(NOTIFICATION)
	}

	const onClose = () => {
		setState(false)
	}

	return (
		<SidebarRight state={isActive} setState={setState}>
			<div
				className="notification-modal side-modal r-side-modal"
				style={{ display: 'inline-block' }}
			>
				<div className="side-modal__top">
					<div className="side-modal__icon" data-fancybox-close="" onClick={onClose}>
						<svg
							className="side-modal__svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19 10.9998H9L12.29 7.70981C12.3837 7.61685 12.4581 7.50625 12.5089 7.38439C12.5597 7.26253 12.5858 7.13183 12.5858 6.99981C12.5858 6.8678 12.5597 6.7371 12.5089 6.61524C12.4581 6.49338 12.3837 6.38278 12.29 6.28982C12.1026 6.10356 11.8492 5.99902 11.585 5.99902C11.3208 5.99902 11.0674 6.10356 10.88 6.28982L6.59 10.5898C6.21441 10.9632 6.00223 11.4702 6 11.9998C6.00487 12.5259 6.21684 13.0289 6.59 13.3998L10.88 17.6998C10.9732 17.7924 11.0838 17.8657 11.2054 17.9155C11.3269 17.9654 11.4571 17.9908 11.5885 17.9904C11.7199 17.9899 11.8499 17.9636 11.9712 17.9128C12.0924 17.8621 12.2024 17.788 12.295 17.6948C12.3876 17.6016 12.4609 17.491 12.5107 17.3694C12.5606 17.2479 12.586 17.1177 12.5856 16.9863C12.5851 16.8549 12.5588 16.7249 12.508 16.6036C12.4573 16.4824 12.3832 16.3724 12.29 16.2798L9 12.9998H19C19.2652 12.9998 19.5196 12.8945 19.7071 12.7069C19.8946 12.5194 20 12.265 20 11.9998C20 11.7346 19.8946 11.4802 19.7071 11.2927C19.5196 11.1052 19.2652 10.9998 19 10.9998Z"
								fill="#0076D8"
							/>
						</svg>
					</div>
					<div className="side-modal__text">Уведомления</div>
				</div>

				{notificationList && notificationList.length > 0 && (
					<div className="notification-modal__container">
						{notificationList.map(item => (
							<NotificationItem key={item.title} data={item} />
						))}

						<div className="notification-modal__bot">
							<div className="notification-modal__bot-text">
								Чтобы посмотреть все уведомления, перейдите в раздел
							</div>
							<div className="notification-modal__wrapper">
								<div className="notification-modal__link" onClick={handleClickLink}>
									«Уведомления»
								</div>
							</div>
						</div>
					</div>
				)}

				{!notificationList ||
					(notificationList.length === 0 && (
						<div className="notification-modal__empty">
							<div className="notification-modal__empty-icon">
								<svg
									className="notification-modal__empty-svg"
									xmlns="http://www.w3.org/2000/svg"
									width={41}
									height={40}
									viewBox="0 0 41 40"
									fill="none"
								>
									<g clipPath="url(#clip0_604_8305)">
										<path
											d="M38.6589 26.4148L34.4913 12.1034C33.6014 9.04204 31.6622 6.35222 28.9825 4.46208C26.3028 2.57193 23.0365 1.59009 19.7035 1.6728C16.3705 1.75551 13.1623 2.89803 10.5895 4.91852C8.01667 6.93902 6.22713 9.72137 5.50567 12.8228L2.2822 26.6439C2.12622 27.3135 2.13003 28.0079 2.29333 28.6759C2.45664 29.3439 2.77527 29.9684 3.22573 30.5035C3.67619 31.0385 4.24698 31.4703 4.89599 31.7671C5.545 32.0639 6.25566 32.218 6.97555 32.2182H12.7573C13.1252 33.9444 14.1081 35.4963 15.5395 36.611C16.971 37.7257 18.7629 38.3346 20.6116 38.3346C22.4603 38.3346 24.2522 37.7257 25.6837 36.611C27.1151 35.4963 28.098 33.9444 28.4659 32.2182H34.0281C34.7688 32.2179 35.4994 32.0545 36.163 31.7409C36.8265 31.4272 37.4051 30.9718 37.8535 30.41C38.3018 29.8483 38.608 29.1955 38.748 28.5025C38.888 27.8095 38.8564 27.095 38.6589 26.4148ZM20.6116 35.2726C19.6206 35.2687 18.655 34.9731 17.847 34.4264C17.0391 33.8796 16.4281 33.1084 16.0978 32.2182H25.1254C24.7951 33.1084 24.1841 33.8796 23.3762 34.4264C22.5682 34.9731 21.6026 35.2687 20.6116 35.2726ZM35.3024 28.5605C35.1528 28.7494 34.959 28.9024 34.7363 29.0071C34.5137 29.1119 34.2684 29.1655 34.02 29.1638H6.97555C6.73553 29.1637 6.49859 29.1123 6.2822 29.0134C6.06582 28.9144 5.87552 28.7704 5.72536 28.592C5.5752 28.4137 5.469 28.2054 5.41461 27.9827C5.36021 27.7599 5.35902 27.5284 5.4111 27.3052L8.63458 13.484C9.20258 11.05 10.6083 8.8667 12.6282 7.28123C14.6481 5.69576 17.1663 4.79911 19.7825 4.73381C22.3987 4.66851 24.9628 5.43831 27.0671 6.92084C29.1713 8.40337 30.6951 10.5135 31.3961 12.9159L35.5637 27.2273C35.6317 27.4536 35.6435 27.6919 35.5982 27.9233C35.5528 28.1547 35.4516 28.3729 35.3024 28.5605Z"
											fill="#001F38"
										/>
										<path
											d="M38.6589 26.4148L34.4913 12.1034C33.6014 9.04204 31.6622 6.35222 28.9825 4.46208C26.3028 2.57193 23.0365 1.59009 19.7035 1.6728C16.3705 1.75551 13.1623 2.89803 10.5895 4.91852C8.01667 6.93902 6.22713 9.72137 5.50567 12.8228L2.2822 26.6439C2.12622 27.3135 2.13003 28.0079 2.29333 28.6759C2.45664 29.3439 2.77527 29.9684 3.22573 30.5035C3.67619 31.0385 4.24698 31.4703 4.89599 31.7671C5.545 32.0639 6.25566 32.218 6.97555 32.2182H12.7573C13.1252 33.9444 14.1081 35.4963 15.5395 36.611C16.971 37.7257 18.7629 38.3346 20.6116 38.3346C22.4603 38.3346 24.2522 37.7257 25.6837 36.611C27.1151 35.4963 28.098 33.9444 28.4659 32.2182H34.0281C34.7688 32.2179 35.4994 32.0545 36.163 31.7409C36.8265 31.4272 37.4051 30.9718 37.8535 30.41C38.3018 29.8483 38.608 29.1955 38.748 28.5025C38.888 27.8095 38.8564 27.095 38.6589 26.4148ZM20.6116 35.2726C19.6206 35.2687 18.655 34.9731 17.847 34.4264C17.0391 33.8796 16.4281 33.1084 16.0978 32.2182H25.1254C24.7951 33.1084 24.1841 33.8796 23.3762 34.4264C22.5682 34.9731 21.6026 35.2687 20.6116 35.2726Z"
											fill="#0076D8"
										/>
										<path
											d="M38.7936 10C38.7936 14.5794 35.0813 18.2917 30.502 18.2917C25.9226 18.2917 22.2103 14.5794 22.2103 10C22.2103 5.42064 25.9226 1.70833 30.502 1.70833C35.0813 1.70833 38.7936 5.42064 38.7936 10Z"
											fill="#EC003E"
											stroke="white"
											strokeWidth="3.41667"
										/>
									</g>
									<defs>
										<clipPath id="clip0_604_8305">
											<rect
												width={40}
												height={40}
												fill="white"
												transform="translate(0.5)"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
							<div className="notification-modal__empty-title">
								У Вас нет новых уведомлений
							</div>
							<div className="notification-modal__bot-text">
								Чтобы посмотреть все уведомления, перейдите в раздел
							</div>
							<div className="notification-modal__wrapper">
								{process.env.REACT_APP_ENV !== 'production' && (
									<div
										className="notification-modal__link"
										onClick={handleClickLink}
									>
										«Уведомления»
									</div>
								)}
							</div>
						</div>
					))}
				<button
					type="button"
					className="fancybox-button fancybox-close-small"
					title="Close"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
					</svg>
				</button>
			</div>
		</SidebarRight>
	)
}

export default NotificationsSidebar
