import { FC, useState } from 'react'

import { AxiosResponse } from 'axios'
import Button from 'src/components/ui/button/Button'
import Loader from 'src/components/ui/loader/Loader'
import { PAYMENTEDITAUTO } from 'src/routes/data'
import { API_DOMAIN } from 'src/shared/api/service/endpoints'
import { getFromLocalStorage } from 'src/shared/api/service/helpers/localStorage.helper'
import $api from 'src/shared/api/service/request'
import useModal from 'src/shared/hooks/useModal'
import { Autopayment, BaseResponse } from 'src/shared/types/swagger'

import AutoPaymentStatus from '../components/autpaymentStatus/AutoPaymentStatus'

import './AutoPaymentCard.scss'

//TASK: кнопка настроить

interface IProps {
	data: Autopayment
	agreement: number
}
const AutoPaymentCard: FC<IProps> = ({ data, agreement }) => {
	const [isLoading, setIsLoading] = useState(false)
	const { open } = useModal()

	// удаление автоплатежа
	const deleteAutoPayment = () => {
		setIsLoading(true)

		const autopay_id = data.id_autopay
		const fetchData = async () => {
			try {
				const response: AxiosResponse<BaseResponse> = await $api.delete(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/autopay/${autopay_id}/`
				)
				const { data } = response
				open({
					text: data?.data?.title || data?.client_message || 'Изменения сохранены',
					modalType: 'success',
					isReload: true,
					description: data?.data?.description || ''
				})
			} catch (error: any) {
				console.info('error', error)
				open({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	// удаление автоплатежа

	return (
		<>
			<Loader state={isLoading} />
			<div className="section__container">
				<div className="payment-page__wrapper r-auto-payment">
					<div className="payment-page__autopay-top">
						<h2 className="section__title alt">Автоплатеж</h2>
						{data.autopay_status && (
							<div className="payment-page__autopay-label">
								<AutoPaymentStatus
									status={data.autopay_status}
									msg_autopay={data.msg_autopay}
								/>
							</div>
						)}
					</div>
					<div className="payment-page__autopay-grid">
						{agreement && (
							<div className="payment-page__autopay-item">
								<div className="payment-page__label">Номер договора</div>
								<div className="payment-page__text">{agreement}</div>
							</div>
						)}

						{data.debit_date && (
							<div className="payment-page__autopay-item">
								<div className="payment-page__label">Дата списания</div>
								<div className="payment-page__text">
									Каждое {data.debit_date} число месяца
								</div>
							</div>
						)}
						{data.check_email && (
							<div className="payment-page__autopay-item">
								<div className="payment-page__label">Email адрес</div>
								<div className="payment-page__text">{data.check_email}</div>
							</div>
						)}
						{data.card_num && (
							<div className="payment-page__autopay-item">
								<div className="payment-page__label">Карта списания</div>
								<div className="payment-page__text">{data.card_num}</div>
							</div>
						)}
						{data.amount && (
							<div className="payment-page__autopay-item">
								<div className="payment-page__label">Сумма списания</div>
								<div className="payment-page__autopay-price">{data.amount} ₽</div>
							</div>
						)}
					</div>

					{/* //кнопки настроить и удалить */}
					<div className="payment-page__autopay-bot">
						<div className="payment-page__autopay-wrapper">
							<Button
								text={'Настроить'}
								theme={'outline'}
								size={'small'}
								href={PAYMENTEDITAUTO + `/${data.id_autopay}`}
							/>
						</div>

						{/* //Кнопка удаления автоплатежа */}
						<div className="payment-page__delete" onClick={deleteAutoPayment}>
							<div className="payment-page__delete-icon">
								<svg
									className="payment-page__delete-svg"
									width={24}
									height={24}
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M13.0604 12L17.7803 7.28068C18.0732 6.9877 18.0732 6.51269 17.7803 6.21974C17.4873 5.92675 17.0123 5.92675 16.7193 6.21974L12 10.9395L7.28068 6.21974C6.9877 5.92675 6.51269 5.92675 6.21974 6.21974C5.92678 6.51272 5.92675 6.98773 6.21974 7.28068L10.9395 12L6.21974 16.7193C5.92675 17.0123 5.92675 17.4873 6.21974 17.7803C6.51272 18.0732 6.98773 18.0732 7.28068 17.7803L12 13.0604L16.7193 17.7803C17.0123 18.0732 17.4873 18.0732 17.7802 17.7803C18.0732 17.4873 18.0732 17.0123 17.7802 16.7193L13.0604 12Z"
										fill="white"
									/>
								</svg>
							</div>
							<div className="payment-page__delete-text">Удалить автоплатеж</div>
						</div>
						{/* //Кнопка удаления автоплатежа */}
					</div>
					{/* //кнопки настроить и удалить */}
				</div>
			</div>
		</>
	)
}

export default AutoPaymentCard
