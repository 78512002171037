import { AxiosResponse } from 'axios'
import { IModalData } from 'src/redux/slices/modalSlice'
import { BaseResponse } from 'src/shared/types/swagger'

export function openModalConfig(
	response: AxiosResponse<BaseResponse>,
	isError: boolean = false
): IModalData {
	return {
		text_html: response?.data?.data?.text_html || '',
		text:
			response?.data?.data?.title ||
			response?.data?.client_message ||
			(isError ? 'Ошибка' : 'Изменения сохранены'),
		description: response?.data?.data?.description || '',
		modalType: isError ? 'error' : 'success'
	}
}
