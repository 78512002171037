import { FC } from 'react'

import { ContactsResponse } from 'src/shared/types/swagger'

import SidebarRight from '../../sidebarRight/SidebarRight'

import './FeedBackContacts.scss'

interface IProps {
	state: boolean
	setState: (prev: any) => void
	data: ContactsResponse
}

const FeedBackContacts: FC<IProps> = ({ state, setState, data }) => {
	return (
		data && (
			<SidebarRight state={state} setState={setState} overlayOff={true}>
				<div
					className="feedback-modal fancybox-content"
					data-fancy-modal="feedback"
					style={{ display: 'inline-block' }}
				>
					<div className="feedback-modal__inner">
						<div className="feedback-modal__top">
							<div
								className="feedback-modal__top-icon"
								onClick={() => {
									setState((prev: boolean) => !prev)
								}}
							>
								<svg
									className="feedback-modal__top-svg"
									width={24}
									height={24}
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19 10.9998H9L12.29 7.70981C12.3837 7.61685 12.4581 7.50625 12.5089 7.38439C12.5597 7.26253 12.5858 7.13183 12.5858 6.99981C12.5858 6.8678 12.5597 6.7371 12.5089 6.61524C12.4581 6.49338 12.3837 6.38278 12.29 6.28982C12.1026 6.10356 11.8492 5.99902 11.585 5.99902C11.3208 5.99902 11.0674 6.10356 10.88 6.28982L6.59 10.5898C6.21441 10.9632 6.00223 11.4702 6 11.9998C6.00487 12.5259 6.21684 13.0289 6.59 13.3998L10.88 17.6998C10.9732 17.7924 11.0838 17.8657 11.2054 17.9155C11.3269 17.9654 11.4571 17.9908 11.5885 17.9904C11.7199 17.9899 11.8499 17.9636 11.9712 17.9128C12.0924 17.8621 12.2024 17.788 12.295 17.6948C12.3876 17.6016 12.4609 17.491 12.5107 17.3694C12.5606 17.2479 12.586 17.1177 12.5856 16.9863C12.5851 16.8549 12.5588 16.7249 12.508 16.6036C12.4573 16.4824 12.3832 16.3724 12.29 16.2798L9 12.9998H19C19.2652 12.9998 19.5196 12.8945 19.7071 12.7069C19.8946 12.5194 20 12.265 20 11.9998C20 11.7346 19.8946 11.4802 19.7071 11.2927C19.5196 11.1052 19.2652 10.9998 19 10.9998Z"
										fill="#0076D8"
									/>
								</svg>
							</div>
							<div className="feedback-modal__top-text">Обратная связь</div>
						</div>
						<div className="feedback-modal__block active">
							<div className="feedback-modal__contacts">
								<div className="feedback-modal__contacts-blocks">
									<div className="feedback-modal__contacts-block">
										<div className="feedback-modal__contacts-text">
											{data.contact_center.title}
										</div>
										<div className="feedback-modal__contacts-wrapper">
											<a
												className="feedback-modal__contacts-heading"
												href={`tel:${data.contact_center.phone}`}
											>
												{data.contact_center.phone}
											</a>
										</div>
										<div className="feedback-modal__contacts-label">
											{data.contact_center.working_hours}
										</div>
									</div>
									<div className="feedback-modal__contacts-block">
										<div className="feedback-modal__contacts-text">
											{data.technical_support.title}
										</div>
										<div className="feedback-modal__contacts-wrapper">
											<a
												className="feedback-modal__contacts-heading"
												href={`tel:${data.technical_support.phone}`}
											>
												{data.technical_support.phone}
											</a>
										</div>
										<div className="feedback-modal__contacts-label">
											{data.technical_support.working_hours}
										</div>
									</div>
									<div className="feedback-modal__contacts-block">
										<div className="feedback-modal__contacts-text">
											Офисы приёма клиентов:
										</div>
										<div className="feedback-modal__contacts-label">
											{data.client_reception_offices.working_hours}
										</div>
										<div className="feedback-modal__contacts-list">
											{data.client_reception_offices.offices.map(address => (
												<div
													className="feedback-modal__contacts-item"
													key={address.address}
												>
													<a
														className="feedback-modal__contacts-link"
														href={address.map_url}
													>
														{address.address}
													</a>
												</div>
											))}
										</div>
									</div>
									<div className="feedback-modal__contacts-block">
										<div className="feedback-modal__contacts-btn">
											<a
												className="button button--outline"
												href={data.website_url}
												target="blank"
											>
												Сайт ПАКТ
											</a>
										</div>
									</div>
								</div>
								<div className="feedback-modal__contacts-bot">
									<a
										className="feedback-modal__contacts-icon"
										href={data.vk_url}
										target="blank"
									>
										<svg
											className="feedback-modal__contacts-svg"
											xmlns="http://www.w3.org/2000/svg"
											width={24}
											height={24}
											viewBox="0 0 24 24"
											fill="none"
										>
											<path
												d="M21.579 6.85495C21.719 6.38995 21.579 6.04895 20.917 6.04895H18.724C18.166 6.04895 17.911 6.34395 17.771 6.66795C17.771 6.66795 16.656 9.38695 15.076 11.15C14.566 11.663 14.333 11.825 14.055 11.825C13.916 11.825 13.714 11.663 13.714 11.198V6.85495C13.714 6.29695 13.553 6.04895 13.088 6.04895H9.642C9.294 6.04895 9.084 6.30695 9.084 6.55295C9.084 7.08095 9.874 7.20295 9.955 8.69095V11.919C9.955 12.6259 9.828 12.755 9.548 12.755C8.805 12.755 6.997 10.026 5.924 6.90195C5.715 6.29495 5.504 6.04995 4.944 6.04995H2.752C2.125 6.04995 2 6.34495 2 6.66895C2 7.25095 2.743 10.1309 5.461 13.9399C7.273 16.5409 9.824 17.951 12.148 17.951C13.541 17.951 13.713 17.6379 13.713 17.0979V15.132C13.713 14.5059 13.846 14.38 14.287 14.38C14.611 14.38 15.169 14.544 16.47 15.797C17.956 17.283 18.202 17.95 19.037 17.95H21.229C21.855 17.95 22.168 17.6369 21.988 17.0189C21.791 16.4039 21.081 15.509 20.139 14.45C19.627 13.846 18.862 13.196 18.629 12.871C18.304 12.452 18.398 12.267 18.629 11.895C18.63 11.896 21.301 8.13395 21.579 6.85495Z"
												fill="white"
											/>
										</svg>
									</a>
									{/* <a
                    className="feedback-modal__contacts-icon"
                    href={data.vk_url}
                    target="blank"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.28746 10.4036L14.7965 5.35766C16.0312 4.83205 20.2188 3.15008 20.2188 3.15008C20.2188 3.15008 22.1515 2.41422 21.9905 4.20131C21.9368 4.93717 21.5073 7.51267 21.0778 10.2984L19.7356 18.5506C19.7356 18.5506 19.6283 19.7595 18.7156 19.9697C17.8029 20.18 16.2997 19.2339 16.0312 19.0236C15.8165 18.8659 12.0047 16.5007 10.6089 15.3443C10.2331 15.029 9.80359 14.3982 10.6626 13.6624C12.5953 11.9278 14.9038 9.77281 16.2997 8.40622C16.9439 7.77548 17.5882 6.30376 14.9038 8.09085L7.334 13.0842C7.334 13.0842 6.47502 13.6098 4.86442 13.1367C3.25382 12.6637 1.37478 12.033 1.37478 12.033C1.37478 12.033 0.0863031 11.2445 2.28746 10.4036Z"
                        fill="white"
                      />
                    </svg>
                  </a> */}
									<a
										className="feedback-modal__contacts-icon"
										href={data.youtube_url}
										target="blank"
									>
										<svg
											className="feedback-modal__contacts-svg"
											xmlns="http://www.w3.org/2000/svg"
											width={24}
											height={24}
											viewBox="0 0 24 24"
											fill="none"
										>
											<g clipPath="url(#clip0_3552_70550)">
												<path
													d="M23.5 6.50695C23.3641 6.02219 23.0994 5.5833 22.734 5.23695C22.3583 4.87996 21.8978 4.62459 21.396 4.49495C19.518 3.99995 11.994 3.99995 11.994 3.99995C8.85734 3.96427 5.72144 4.12123 2.60401 4.46995C2.1022 4.60917 1.64257 4.87024 1.26601 5.22995C0.896007 5.58595 0.628007 6.02495 0.488007 6.50595C0.1517 8.3177 -0.0117011 10.1573 6.83166e-06 12C-0.0119932 13.841 0.151007 15.68 0.488007 17.494C0.625007 17.973 0.892007 18.41 1.26301 18.763C1.63401 19.116 2.09601 19.371 2.60401 19.506C4.50701 20 11.994 20 11.994 20C15.1347 20.0357 18.2746 19.8787 21.396 19.53C21.8978 19.4003 22.3583 19.145 22.734 18.788C23.104 18.435 23.367 17.996 23.499 17.518C23.8441 15.7069 24.0119 13.8665 24 12.023C24.026 10.1716 23.8584 8.32252 23.5 6.50595V6.50695ZM9.60201 15.424V8.57695L15.862 12.001L9.60201 15.424Z"
													fill="white"
												/>
											</g>
											<defs>
												<clipPath id="clip0_3552_70550">
													<rect width={24} height={24} fill="white" />
												</clipPath>
											</defs>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* <button
          type="button"
          data-fancybox-close=""
          className="fancybox-button fancybox-close-small"
          title="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version={1}
            viewBox="0 0 24 24"
          >
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
          </svg>
        </button> */}
				</div>
			</SidebarRight>
		)
	)
}

export default FeedBackContacts
